import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import userPlaceholder from '../../assets/images/user_placeholder.png'
import { colors } from '../../constants'
import { TextLeft, BaseCard } from '../globalComponents'

const MessageWrapper = styled.div`
  margin: 10px 0;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.owner ? 'row-reverse' : 'row')};
`
const MessageBodyWrapper = styled(BaseCard)`
  padding: 10px;
  margin: 0 10px;
  width: 100%;
  position: relative;
`

const MessageBody = styled.div``
const MessageDate = styled.div`
  position: absolute;
  font-size: 12px;
  bottom: 2px;
  right: 5px;
  color: ${colors.grayDark};
`

const MessageAvatar = styled.div`
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  height: 50px;
  width: 50px;
  border-radius: 50%;
`

export default function Message({ avatarUrl, owner, message, createdAt }) {
  const showMessageTime = () => {
    const messageTime = moment(createdAt)
    const currentTime = moment()
    const diffInSeconds = currentTime.diff(messageTime, 'seconds')
    if (diffInSeconds >= 0 && diffInSeconds < 60) return 'Agora'

    const diffInMinutes = currentTime.diff(messageTime, 'minutes')

    if (diffInMinutes > 0 && diffInMinutes < 60) {
      return pluralize(diffInMinutes, 'minutos')
    }

    const diffInHours = currentTime.diff(messageTime, 'hours')

    if (diffInHours > 0 && diffInHours < 24) {
      return pluralize(diffInHours, 'horas')
    }

    const diffInDays = currentTime.diff(messageTime, 'days')

    if (diffInDays > 0 && diffInDays < 31) {
      return pluralize(diffInDays, 'dias')
    }
  }

  const pluralize = (amount, type) => {
    let timeMessage = `há ${amount} ${type}`
    if (amount > 1) timeMessage += 's'
    return timeMessage
  }

  return (
    <MessageWrapper owner={owner}>
      <MessageAvatar src={avatarUrl || userPlaceholder} />
      <MessageBodyWrapper>
        <MessageBody>
          <TextLeft>{message}</TextLeft>
          <MessageDate>
            <span>{showMessageTime(createdAt)}</span>
          </MessageDate>
        </MessageBody>
      </MessageBodyWrapper>
    </MessageWrapper>
  )
}
