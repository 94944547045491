import React from 'react'
import { BlueButton } from '../../../components/Buttons'
import styled from 'styled-components'
import { TextLeft, SubTitleLeft, TitleLeft, BaseCard } from '../../../components/globalComponents'

const ReservationStatusWrapper = styled(BaseCard)`
  text-align: left;
  padding: 10px 10px 20px 10px;
`

export default function ReservationStatus({ reservation, handleStatusChange }) {
  const renderActionButton = () => {
    switch (reservation.status) {
      case 'pending_confirmation':
        return <BlueButton onClick={async () => await handleStatusChange('approved')}>Aceitar</BlueButton>
      case 'approved':
        return <BlueButton onClick={async () => await handleStatusChange('canceled')}>Cancelar</BlueButton>
      default:
        return <div></div>
    }
  }

  return (
    <ReservationStatusWrapper>
      <TitleLeft>{reservation.status_msg}</TitleLeft>
      <SubTitleLeft>{reservation.info_msg}</SubTitleLeft>
      <TextLeft>
        Por favor, leia os termos e condições completos relacionados ao reagendamento ou cancelamento de uma reserva de
        hóspede. Você pode estar sujeito a cobrir custos de deslocamento de hóspedes e multas de até 5% do total de
        reservas.
      </TextLeft>
      {renderActionButton()}
    </ReservationStatusWrapper>
  )
}
