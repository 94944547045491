import React, { useContext, forwardRef, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap'
import { FirebaseContext } from '../../Firebase'
import { colors } from '../../../constants'
import { TextLeft } from '../../globalComponents'

const PendingNotification = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: ${colors.pink};
  position: absolute;
  top: 0px;
  right: 10px;
  display: ${({ isPending }) => (isPending ? 'block' : 'none')};
`

const InternalButton = styled.button`
  position: relative;
  padding: 0 7px;
  margin: 0 5px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
  color: ${colors.gray};
  background-color: transparent;
  border-color: transparent;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: underline;
    color: ${colors.grayDark};
  }

  &:active {
    text-decoration: underline;
    color: ${colors.grayDark};
  }
`

const NotificationWrapper = styled.div`
  position: relative;
`

export default function Notifications() {
  const [notifications, setNotifications] = useState([])
  const [pendingNotification, setPendingNotification] = useState(false)
  const firebase = useContext(FirebaseContext)

  const currentUser = firebase.auth.currentUser

  const userId = currentUser && currentUser.uid

  let notificationRef

  if (userId) {
    notificationRef = firebase.db
      .collection('users')
      .doc(userId)
      .collection('notifications')
      .where('is_read', '==', false)
  }

  const NotificationButton = forwardRef(({ children, onClick }, ref) => (
    <NotificationWrapper>
      <InternalButton
        href=''
        ref={ref}
        onClick={e => {
          e.preventDefault()
          onClick(e)
        }}
      >
        {children}
      </InternalButton>
      <PendingNotification isPending={pendingNotification}></PendingNotification>
    </NotificationWrapper>
  ))

  useEffect(() => {
    if (notificationRef) {
      const unsubcribe = notificationRef.onSnapshot(function (querySnapshot) {
        let _notifications = []
        let pendingNotifications = false

        querySnapshot.forEach(doc => {
          const data = doc.data()

          if (!data.is_read) {
            pendingNotifications = true
          }

          _notifications.push(data)
        })

        setPendingNotification(pendingNotifications)
        setNotifications(_notifications)
      })

      return unsubcribe
    }
  }, [])

  const renderNotifications = notifications => {
    if (notifications && notifications.length) {
      return notifications.map((notification, index) => {
        return (
          <Dropdown.Item key={index}>
            <Link to={notification.url}>{notification.message}</Link>
          </Dropdown.Item>
        )
      })
    } else {
      return (
        <Dropdown.Item>
          <TextLeft>Sem notificações novas</TextLeft>
        </Dropdown.Item>
      )
    }
  }

  const markAllNotificationAsRead = event => {
    if (notificationRef && !event) {
      notificationRef.get().then(function (querySnapshot) {
        querySnapshot.forEach(doc => {
          doc.ref.update({
            is_read: true,
          })
        })
      })
    }
  }

  return (
    <Dropdown onToggle={markAllNotificationAsRead}>
      <Dropdown.Toggle as={NotificationButton}>Notificações</Dropdown.Toggle>
      <Dropdown.Menu>{renderNotifications(notifications)}</Dropdown.Menu>
    </Dropdown>
  )
}
