import React, { useContext, useState } from 'react'
import { FirebaseContext } from '../../Firebase'

import { PinkButton, TextButton } from '../../Buttons'
import * as Yup from 'yup'

import { SubTitleCenter } from '../../globalComponents'
import useApi from '../../../hooks/useApi'
import { useFormik } from 'formik'
import Alert from '@material-ui/lab/Alert'
import { CircularProgress, TextField } from '@material-ui/core'
import styled from 'styled-components'

const PasswordWarning = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
`

export default function SignUpForm({ callback, setAction, setIsLoading }) {
  const [showConfirmEmailMessage, setShowConfirmEmailMessage] = useState(false)
  const firebase = useContext(FirebaseContext)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const api = useApi()
  const Schema = Yup.object().shape({
    email: Yup.string().required('Email Obrigatório'),
    lastName: Yup.string().required('Sobrenome é Obrigatório'),
    firstName: Yup.string().required('Nome é Obrigatório'),
    password: Yup.string().min(6, 'Senha deve ser igual ou maior a 6 caracteres').required('Senha Obrigatória'),
    confirmPassword: Yup.string().when('password', {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'As duas senhas devem ser iguais'),
    }),
  })

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Schema,
    onSubmit: async values => {
      await createUser()
    },
  })

  async function createUser() {
    const formValid = await formik.validateForm()
    if (Object.keys(formValid).length == 0) {
      setLoading(true)
      const { email, password, firstName, lastName } = formik.values
      // show loading

      firebase
        .signUpWithEmailAndPassword(email, password)
        .then(async authUser => {
          const payload = { token: authUser.user.xa, first_name: firstName, last_name: lastName }
          await api.post('/user', payload)
          await firebase.auth.currentUser.sendEmailVerification()
          setLoading(false)
          callback()
          setShowConfirmEmailMessage(true)
          setErrorMessage('')
          await firebase.auth.signOut()
        })
        .catch(error => {
          if (error.code.includes('email-already-in-use')) {
            setErrorMessage('Este e-mail já está sendo utilizado')
          }
          if (error.code.includes('wrong-password')) {
            setErrorMessage('Senha incorreta')
          }
          if (error.code.includes('invalid-email')) {
            setErrorMessage('Insira um email válido')
          }
          if (error.code.includes('too-many-request')) {
            setErrorMessage('Tente Novamente mais tarde')
          }
          setLoading(false)
        })
    } else {
      setErrorMessage('Tente Novamente mais tarde')
    }
  }
  return (
    <div>
      {errorMessage.length > 0 && <Alert severity='error'>{errorMessage}</Alert>}
      {!showConfirmEmailMessage && (
        <>
          {!loading ? (
            <form onSubmit={formik.handleSubmit}>
              <TextField
                error={formik.touched.firstName && formik.errors.firstName}
                id='outlined-error'
                label='Nome'
                autoComplete='give-name'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.firstName}
                helperText={formik.touched.firstName && formik.errors.firstName}
                type='text'
                name='firstName'
                variant='outlined'
                style={{ width: '100%', marginBottom: 10, marginTop: 20 }}
              />
              <TextField
                error={formik.touched.lastName && formik.errors.lastName}
                id='outlined-error'
                label='Sobrenome'
                autoComplete='family-name'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.lastName}
                helperText={formik.touched.lastName && formik.errors.lastName}
                type='text'
                name='lastName'
                variant='outlined'
                style={{ width: '100%', marginBottom: 10 }}
              />

              <TextField
                error={formik.touched.email && formik.errors.email}
                id='outlined-error'
                label='Email'
                autoComplete='email'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
                type='email'
                name='email'
                variant='outlined'
                style={{ width: '100%', marginBottom: 10 }}
              />

              <TextField
                error={formik.touched.password && formik.errors.password}
                id='outlined-error'
                label='Senha'
                autoComplete='new-password'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                helperText={formik.touched.password && formik.errors.password}
                type='password'
                name='password'
                variant='outlined'
                style={{ width: '100%', marginBottom: 10 }}
              />

              <PasswordWarning>* Senha deve conter mínimo 6 caracteres</PasswordWarning>

              <TextField
                error={formik.errors.confirmPassword}
                id='outlined-error'
                label='Confirme sua senha'
                autoComplete='new-password'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                helperText={formik.errors.confirmPassword}
                type='password'
                name='confirmPassword'
                variant='outlined'
                style={{ width: '100%', marginBottom: 10 }}
              />

              <PinkButton type='submit' disabled={formik.isSubmitting} style={{ width: '100%', margin: '10px 0' }}>
                Cadastre-se
              </PinkButton>
              <div style={{ textAlign: 'center' }}>
                <TextButton onClick={() => setAction('sign_in')}>Já tem conta?</TextButton>
              </div>
            </form>
          ) : (
            <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>
              <CircularProgress />
            </div>
          )}
        </>
      )}

      {showConfirmEmailMessage && (
        <div style={{ padding: '20px 0' }}>
          <SubTitleCenter>Enviamos um email para sua conta, confirme para continuar.</SubTitleCenter>
        </div>
      )}
    </div>
  )
}
