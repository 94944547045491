export default {
  flexivel: {
    first: '100% reembolso até 2 dias antes da data do evento',
    second: '50% reembolso entre 2 dias e 24hrs antes da hora de início do evento',
  },
  moderado: {
    first: '100% reembolso até 15 dias antes da data do evento',
    second: '50% reembolso entre de 15 e 2 dias antes da hora de início do evento',
  },
  rigido: {
    first: '100% reembolso até 30 dias da data do evento',
    second: '50% reembolso entre de 30 e 15 dias antes da hora de início do evento',
  },
}
