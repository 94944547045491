import React, { useState, Suspense } from 'react'
import Reservation from '../pages/Host/Reservation'
import VerifyEmail from '../pages/VerifyEmail'

const HomePage = React.lazy(() => import('../pages/HomePage/index'))
const SearchPage = React.lazy(() => import('../pages/SearchSpacesPage/index'))
const SpacePage = React.lazy(() => import('../pages/SpacePage/index'))

const PrivacityPolicy = React.lazy(() => import('../pages/PrivacityPolicy/index'))
const TermsAndConditions = React.lazy(() => import('../pages/TermsAndConditions/index'))
const OrganizerReservationPage = React.lazy(() => import('../pages/Organizer/Reservation/index'))
const HostCompleteProfile = React.lazy(() => import('../pages/Host/CompleteProfile/index'))
const ReservationsPage = React.lazy(() => import('../pages/Organizer/Reservations/index'))
const HostReservationsPage = React.lazy(() => import('../pages/Host/Reservation'))
const Checkout = React.lazy(() => import('../pages/Organizer/Checkout/index'))
const ProfilePage = React.lazy(() => import('../pages/ProfilePage/index'))
const HostBankAccounts = React.lazy(() => import('../pages/Host/HostBankAccounts/index'))
const PaymentsPage = React.lazy(() => import('../pages/Organizer/Payments/index'))
const Listing = React.lazy(() => import('../pages/Host/Listing/index'))
const Host = React.lazy(() => import('../pages/Host/index'))
const FavoritePage = React.lazy(() => import('../pages/Organizer/Favorites/index'))
const KnowMore = React.lazy(() => import('../pages/KnowMore'))

export const routes = [
  {
    path: '/',
    component: HomePage,
    private: false,
  },
  {
    path: '/profile',
    component: ProfilePage,
    private: true,
  },
  {
    path: '/anfitriao/contas-bancarias',
    component: HostBankAccounts,
    private: true,
  },
  {
    path: '/pagamentos',
    component: PaymentsPage,
    private: true,
  },
  {
    path: '/anunciar-espaco',
    component: Listing,
    private: true,
  },
  {
    path: '/editar-espaco/:id',
    component: Listing,
    private: true,
  },
  {
    path: '/notificacoes',
    component: ProfilePage,
    private: true,
  },
  {
    path: '/espacos',
    component: SearchPage,
    private: false,
  },
  {
    path: '/espacos/:id',
    component: SpacePage,
    private: false,
  },
  {
    path: '/organizador/checkout',
    component: Checkout,
    private: true,
  },
  {
    path: '/organizador/reservas',
    component: ReservationsPage,
    private: true,
  },
  {
    path: '/organizador/reserva/:id',
    component: OrganizerReservationPage,
    private: true,
  },
  {
    path: '/organizador/pagamentos',
    component: PaymentsPage,
    private: true,
  },
  {
    path: '/anfitriao',
    component: Host,
    private: true,
  },
  {
    path: '/anfitriao/reservas',
    component: HostReservationsPage,
    private: true,
  },
  {
    path: '/host/reserva/:id',
    component: Reservation,
    private: true,
  },
  {
    path: '/completar-perfil',
    component: HostCompleteProfile,
    private: true,
  },
  {
    path: '/politicas-de-privacidade',
    component: PrivacityPolicy,
    private: false,
  },
  {
    path: '/termos-e-condicoes',
    component: TermsAndConditions,
    private: false,
  },
  {
    path: '/termos-e-condicoes',
    component: TermsAndConditions,
    private: false,
  },
  {
    path: '/organizador/favoritos',
    component: FavoritePage,
    private: true,
  },
  {
    path: '/saiba-mais',
    component: KnowMore,
    private: false,
  },
  {
    path: '/verificar-email',
    component: VerifyEmail,
    private: false,
  },
]
