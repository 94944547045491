import React, { useContext, useState } from 'react'
import { Container } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import Alert from '@material-ui/lab/Alert'

import { SubTitleCenter, TitleCenter } from '../../components/globalComponents'
import LogoPicoz from '../../components/Logo'
import * as firebase from 'firebase/app'
import { PinkButton } from '../../components/Buttons'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Redirect } from 'react-router-dom'

export default function VerifyEmail({ path }) {
  const [message, setmessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  async function resendEmail() {
    setIsLoading(true)
    await firebase.auth().currentUser.sendEmailVerification()
    setmessage('Email reenviado com sucesso')
    setIsLoading(false)
  }
  if (firebase.auth().currentUser) {
    if (firebase.auth().currentUser.emailVerified) {
      return <Redirect to={path}></Redirect>
    } else {
      return (
        <Container className='knowMore' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {message.length > 0 && <Alert severity='success'>{message}</Alert>}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '30px 0',
            }}
          >
            <LogoPicoz />
            <TitleCenter className='knowMoreTitle'>Bem Vindo à Picoz !</TitleCenter>
            <TitleCenter className='knowMoreTitle'>Verifique seu email antes de continuar</TitleCenter>
            <SubTitleCenter className='knowMoreTitle'>
              {firebase.auth().currentUser ? firebase.auth().currentUser.email : ''}
            </SubTitleCenter>
            {!isLoading ? (
              <PinkButton type='button' onClick={() => resendEmail()} style={{ width: '100%', margin: '10px 0' }}>
                Reenviar Email
              </PinkButton>
            ) : (
              <CircularProgress />
            )}
          </div>
        </Container>
      )
    }
  } else {
    return (
      <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>
        <CircularProgress />
      </div>
    )
  }
}
