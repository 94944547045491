import React from 'react'
import styled from 'styled-components'
import { colors } from '../../constants'

const InternalButton = styled.button`
  padding: 0 7px;
  margin: 0 5px;
  font-size: 16px;
  min-height: 40px;

  font-weight: 700;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.5s ease;
  border: solid 1px ${colors.blue};
  color: ${colors.blue};
  background-color: transparent;
  border-radius: 4px;
  border-color: ${colors.blue};

  &:focus {
    outline: none;
  }

  &:active {
    color: ${colors.white};
    background: ${colors.blue};
  }
`

export default function BlueButton({ children, ...props }) {
  return <InternalButton {...props}>{children}</InternalButton>
}
