import { useState, useContext } from 'react'
import { UserContext } from '../../context'

export function useNavbar() {
  const [action, setAction] = useState('sign_up')
  const { user, showModal, setShowModal, area, setArea } = useContext(UserContext)

  const handleActionClick = action => {
    setShowModal(true)
    setAction(action)
  }

  return [showModal, setShowModal, action, handleActionClick, user, setAction, area, setArea]
}
