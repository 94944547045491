export default {
  white: '#ffffff',
  blue: '#0F3B7D',
  blueDark: '#0A244B',
  blueGrey: '#8C9BA5',
  blueLight: 'rgba(15,59,125, 0.2)',
  pink: '#F0308D',
  pinkHover: '#B32369',
  pinkActive: '#771746',
  grayLight: '#CCCCCC',
  grayLightGrayHover: 'rgba(0,0,0, 0.2)',
  grayLightGrayPressed: 'rgba(0,0,0, 0.5)',
  gray: '#9FA0A4',
  grayDark: '#56585C',
  grayMedium: '#7C7C7E',
  black: '#000000',
  blackLight: '#303133',
  redError: '#ff3e3e'
}
