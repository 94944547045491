import React from 'react'
import styled from 'styled-components'
import { colors } from '../../constants'

const InternalButton = styled.button`
  padding: 0 7px;
  font-size: 16px;
  font-weight: 700;
  min-height: 40px;
  cursor: pointer;
  user-select: none;
  transition: opacity 0.5s ease;
  color: ${colors.white};
  background: ${colors.pink};
  border-radius: 4px;
  border: 1px solid ${colors.pink};
  border-color: ${colors.pink};

  &:hover {
    background: ${colors.pinkHover};
    border: 1px solid $pink-hover;
  }

  &:active {
    background: ${colors.pinkActive};
    border: 1px solid ${colors.pinkActive};
  }

  &:focus {
    outline: none;
  }
`

export default function PinkButton({ children, ...props }) {
  return <InternalButton {...props}>{children}</InternalButton>
}
