import styled from 'styled-components'
import { colors } from '../constants'

const Title = styled.h3`
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  color: ${colors.grayDark};
  text-align: center;
  font-family: 'AvanirBook';
`

export const TitleCenter = styled(Title)`
  text-align: center;
`

export const TitleLeft = styled(Title)`
  text-align: left;
`

export const TitleRight = styled(Title)`
  text-align: right;
`

const SubTitle = styled.h3`
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  color: ${colors.grayDark};
  font-family: 'AvanirBook';
`

export const SubTitleCenter = styled(SubTitle)`
  text-align: center;
`

export const SubTitleLeft = styled(SubTitle)`
  text-align: left;
`

const Text = styled.p`
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Metropolis' !important;
`

export const TextCenter = styled(Text)`
  text-align: center;
`

export const TextLeft = styled(Text)`
  text-align: left;
`
export const TextRight = styled(Text)`
  text-align: right;
`

export const BaseCard = styled.div`
  margin-top: 20px;
  text-align: center;
  border: 1px solid ${colors.grayLight};
  border-radius: 4px;
  margin-bottom: 35px;
  background-color: ${colors.white};
`
