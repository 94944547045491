import styled from 'styled-components'

import PinkButton from './PinkButton'

const HomeSearchButton = styled(PinkButton)`
  height: 50px;
  font-size: 20px;
  min-width: 160px;
  margin: 10px 10px;

  @media (max-width: 768px) {
    width: 300px;
  }
`

export default HomeSearchButton
