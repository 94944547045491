import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { TitleCenter } from '../globalComponents'
import CancelationProgress from '../CancelationProgress'

export default function TermAndConditionsModal({ show, setShow }) {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <TitleCenter>Termos e Condições</TitleCenter>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'scroll', maxHeight: '80vh' }}>
        <div className='terms_and_conditions'>
          <h2>TERMOS E CONDIÇÕES DE USO – PICOZ.CO </h2>

          <p>Última atualização: 15 de abril, de 2019 </p>

          <p>
            Bem-vindo a PICOZ.CO. Desenvolvemos uma plataforma online para digitalizar a Reserva e locação de curto
            prazo de Espaços compartilhadospara seus Eventos, incluindo a possibilidade de contratação de outros
            serviços desejados para seu Evento. Nossa plataforma permite que você organize seu Evento de forma
            totalmente digital desde a Reserva, contratação de serviços, assinatura de contratos e
            pagamento/recebimento. Tentamos tornar este termo de serviços fácil de ler e entender. Por favor, reserve um
            tempo para lê-lo com atenção, pois ele rege o uso da nossa plataforma para você poder Reservar e listar
            Espaços e serviços.
          </p>

          <p>
            Você está prestes a fazer o uso dos Serviços da PICOZ.CO, que é operada pela PICOZ.CO, inscrita no CNPJ/MF
            sob o no 30.092.744/0001-44. Estes termos e condições de uso (“Termos e Condições de Uso”) regulam a
            utilização do site (“PICOZ.CO”) e dos Serviços disponibilizados. Ao clicar no botão “Eu li e aceito os
            termos de uso” você concorda e manifesta sua vontade livre, consciente, expressa e informada de cumprir com
            e ser regido por esses Termos e Condições de Uso. Caso não esteja de acordo com estes termos de uso, não
            será permitido a utilização da nossa plataforma ou Serviços.{' '}
          </p>

          <p>
            A PICOZ.CO pode alterar os Termos e Condições de Uso a qualquer momento, mas você sempre será notificado
            antes deles entrarem em vigor e um novo consentimento será solicitado para que você continue a ter acesso à
            PICOZ.CO e aos nossos serviços.{' '}
          </p>

          <p>
            Ao utilizar o site e/ou serviços, mesmo que como teste, você estará vinculado a esses Termos e Condições de
            Uso, concordando com o que está disposto abaixo.{' '}
          </p>

          <p>
            Ao fazer o cadastro no site e aceitar estes Termos e Condições de Uso, você declara e garante que as
            informações prestadas são verdadeiras, que é maior de idade, e declara também possuir os devidos poderes de
            representação para celebrar contratos com as partes aqui envolvidas, tendo como objeto a locação do Espaço e
            contratação de serviços de terceiros.{' '}
          </p>

          <ol>
            <li className='li-terms-and-conditions'>Definições</li>

            <p>Os termos em letras maiúsculas no singular e no plural terão os significados atribuídos abaixo. </p>
            <ol type='I'>
              <li>
                a) Serviços de plataforma digital – A PICOZ.CO é uma plataforma online que oferece vários serviços
                diferentes para seus usuários. Existem três tipos de usuários da PICOZ.CO: 1) Organizadores (nossos
                usuários que usam serviços de Anfitriões e / ou Serviços de Terceiros); 2) Anfitriões (nossos usuários
                que oferecem serviços de locação para Organizadores); e 3) Fornecedores (nossos usuários ou terceiros
                que oferecem serviços de fornecedores para Organizadores). A PICOZ.CO conecta todos eles através da
                plataforma PICOZ.CO (“Plataforma”), que é o mercado on-line que permite que nossos usuários se conectem,
                se comuniquem e transacionem diretamente através de “Reservas”. Estamos melhorando continuamente a
                plataforma para todos os usuários e Reservamos direito de fazer alterações em nosso critério. As
                alterações que fazemos na Plataforma, incluindo a interrupção de determinados recursos, afetam todos os
                usuários e tentamos minimizar a interrupção dos usuários. No entanto, essas alterações podem afetar os
                usuários de maneira diferente. Não nos responsabilizamos se quaisquer alterações específicas que
                fizermos à Plataforma afetarem negativamente como você as utiliza.
                <br />
                b) A PICOZ.CO não possui, cria, vende, revende, fornece, controla, administra, oferece, entrega ou
                fornece quaisquer Anúncios ou serviços de anfitriões ou de terceiros. Somente os Anfitriões são
                responsáveis por seus anúncios e serviços de hospedagem. Quando os membros fazem ou aceitam uma Reserva,
                eles entram em contrato diretamente entre si. A PICOZ.CO não é e não se torna parte ou outro
                participante em qualquer relação contratual entre os Membros.
              </li>

              <li>
                Anfitrião – Significa o proprietário ou legítimo possuidor com devidos poderes para dispor sobre o
                Espaço e serviços relacionados para o Organizador por um período e propósito específico (um “Evento”) ,
                que se cadastre no site PICOZ.CO com o intuito de alugar o referido Espaço para Eventos, por intermédio
                da PICOZ.CO. Os detalhes de cada Espaço, incluindo sua disponibilidade, valor, taxas adicionais, regras
                de uso e outros termos são fornecidos em um “anúncio.” Observe que a PICOZ.CO não possui, aluga ou
                controla os Espaços anunciados e não toma decisões sobre a aceitação de uma Reserva. Além disso, os
                Anfitriões podem fornecer bens ou serviços para um Evento por um custo adicional. Os anfitriões são os
                únicos responsáveis por seus Espaços, anúncions e serviços de locação.
              </li>

              <li>
                Conteúdo da PICOZ.CO – Significa o conteúdo disponibilizado para acesso público ou mediante login no
                site, disponibilizado pela PICOZ.CO.
              </li>

              <li>
                Conteúdo do Anfitrião – Significa o conteúdo informado e/ou disponibilizado pelo Anfitrião no Site.
              </li>
              <li>
                Contrato de Locação Temporária do Espaço – Contrato firmado entre locador e locatário, ou seja,
                Anfitrião e Organizador do Evento, após a Reserva pelo Organizador do Evento e confirmação de
                disponibilidade pelo Anfitrião.
              </li>
              <li>
                Convidados – Significa o conjunto de pessoas que, convidadas pelo Organizador do Evento, participarão do
                Evento no Espaço.
              </li>

              <li>
                Espaço – Significa o imóvel, de propriedade do Anfitrião que, depois de realizada a curadoria, é
                disponibilizado na PICOZ.CO para locação para Evento por qualquer Organizador do Evento.
              </li>

              <li>
                Evento – Significa Evento social, corporativo ou desportivo contanto que dentro dos parâmetros legais e
                regulatórios, seja ele uma refeição (café da manhã, brunch, almoço ou jantar), lançamento de produto,
                reunião, curso, workshop, casamento, ensaio fotográfico, gravação audiovisual, desfile, conferência,
                coletiva de imprensa, feira, happy hour, show, esportes, dentre outros.
              </li>

              <li>
                Organizador do Evento – Significa a pessoa física ou jurídica que realizará o Evento e deseja Reservar
                um Espaço e/ou Serviço por meio da PICOZ.CO, alugando um Espaço oferecido por um dos Anfitriões e
                serviço por um dos parceiros.
              </li>

              <li>
                Reserva – Uma vez que a solicitação do agendamento do Espaço pelo Organizador do Evento for realizado
                pelo site PICOZ.CO e confirmada pelo Anfitrião, estabelece-se um acordo entre o Anfitrião e o
                Organizador do Evento para uso exclusivo do Espaço pelo Organizador do Evento durante o período
                solicitado;
              </li>

              <li>
                Serviços – Significam os serviços prestados pela PICOZ.CO de aproximação e intermediação de interessados
                para a locação de Espaços para Eventos, e de serviços contratados adicionalmente de parceiros.
              </li>
              <li>
                Serviços de Terceiros – Os serviços devidamente prestados pelos parceiros da PICOZ.CO, ou seja, serviços
                terceirizados prestados por pessoas jurídicas distintas da PICOZ.CO, onde a PICOZ.CO serviu de
                facilitador e intermediador para o Organizador do Evento encontrar o serviço adicional solicitado para
                seu Evento.
              </li>
              <li>
                Site – Significa a plataforma digital (www.picoz.co/) no qual são dispostos os Espaços para visualização
                pelos Organizadores dos Eventos, e a partir de onde é realizada a intermediação do aluguel do Espaço por
                meio de Reserva online.
              </li>

              <li>
                Parceiros – Fornecedores tercerizados de serviços ou produtos auxiliares para Eventos contratados por
                meio do site pelo Organizador do Evento, independente da Reserva do Espaço.{' '}
              </li>
            </ol>

            <li className='li-terms-and-conditions'>Serviços</li>

            <p>
              A PICOZ.CO prestará ao Organizador do Evento, todos os serviços escolhidos dentre os disponibilizados, os
              quais incluem, mas não se limitam a, locação de casas, apartamentos, galerias de arte, garagens,
              lavanderias, Espaços de co-working e Espaços para Eventos, disponibilizados por pessoas físicas ou
              jurídicas, para Eventos sociais, refeições, lançamentos de produtos, reuniões, cursos ou workshops,
              casamentos, fotos e filmes, desfiles, conferências, coletivas de imprensa, feiras, happy hours e
              espetáculos, dentre outros.
            </p>

            <p>
              A PICOZ.CO empenhará seus melhores esforços para garantir que as informações do Espaço são verdadeiras e
              estão atualizadas. No entanto, não há garantias de que o Espaço é adequado para a finalidade específica
              almejada pelo Organizador do Evento, tampouco que o Evento será bem sucedido no Espaço escolhido.
            </p>

            <p>
              No momento em que o Organizador do Evento efetuar uma Reserva do Espaço, e essa Reserva ser aprovada pelo
              Anfitrião, Ambos Organizador do Evento e Anfitrião receberão em seus respectivos emails o Contrato de
              Locação Temporária do Espaço com o Anfitrião, sendo que, para que a Reserva possa ser confirmada pela
              PICOZ.CO e o Evento seja realizado, ambas partes deverão assinar eletronicamente o Contrato de Locação
              Temporária do Espaço, podendo o Anfitrião, ou a própria PICOZ.CO solicitar informações e documentos
              adicionais ao Organizador do Evento, conforme políticas e regras internas próprias do Anfitrião e/ou da
              PICOZ.CO.{' '}
            </p>

            <p>
              A PICOZ.CO oferecerá ao Anfitrião o serviço de pré-seleção, curadoria, trabalho fotográfico para melhor
              apresentação do Espaço, divulgação, e intermediação da locação dos Espaços para Organizadores de Eventos
              interessados.
            </p>

            <ol type='I'>
              <li>
                Pré-Seleção - O Anfitrião, ao se interessar pela divulgação de seu Espaço no site, deverá preencher
                formulário de cadastro no qual informará as especificações de sua propriedade. Os dados serão tidos como
                confidenciais e analisados internamente pela PICOZ.CO, para verificar a viabilidade do Espaço receber
                Eventos, assim como sua compatibilidade com a proposta de valor da PICOZ.CO. Serão avaliados aspectos
                como localização, Espaço, limpeza, tamanho, conforto e versatilidade. Ocorrendo a aprovação do Espaço no
                momento da pré-seleção, a equipe da PICOZ.CO entrará em contato com o Anfitrião para agendar o melhor
                horário para a curadoria.
              </li>

              <li>
                Curadoria - A curadoria é uma visita técnica realizada por membro(s) da equipe da PICOZ.CO para avaliar
                a adequação do Espaço. O objetivo é conhecer pessoalmente o ambiente para traçar um perfil detalhado de
                suas características e destacar os diferenciais do Espaço. Durante a curadoria, serão tiradas
                fotografias profissionais do Espaço (podendo incluir fotos panorâmicas). O Anfitrião poderá solicitar um
                tratamento especial do seu Espaço, como fotografias em 360 graus ou em realidade virtual por um valor
                adicional definido em orçamento na hora da solicitação. A curadoria faz parte da pré-seleção do Espaço e
                não garante sua divulgação no site da PICOZ.CO. O Espaço pode ser recusado no momento da curadoria, a
                depender das características e situação legal do Espaço para a realização de Eventos.
              </li>

              <li>
                Divulgação - Após aprovado pela PICOZ.CO, o Espaço do Anfitrião será divulgado no site, a partir da
                descrição detalhada enviada pelo Anfitrião e dos resultados da curadoria. O conteúdo do perfil do Espaço
                no site poderá ser revisado pelo Anfitrião antes da publicação, para garantir a veracidade e precisão
                das informações.
              </li>

              <li>
                Intermediação da comunicação entre Anfitrião e Organizador do Evento – A PICOZ.CO é uma plataforma de
                aproximação entre o Anfitrião e Organizadores dos Eventos interessados na locação do Espaço para
                Eventos, e pode ser acionada sempre que necessário para facilitar a comunicação entre as partes,
                auxiliar na conciliação de interesses, dentre outros.
              </li>

              <li>
                Documentos Adicionais. Por segurança, é possível que a PICOZ.CO solicite alguns documentos
                comprobatórios do Anfitrião e do Espaço, como cópia da cédula de identidade, e dados de matrícula do
                imóvel no qual o Espaço se encontra, entre outros. No momento em que o Organizador do evento Evento
                efetua uma Reserva no site, ele passa a ter uma relação jurídica diretamente com o Anfitrião, sendo que,
                para que a Reserva seja confirmada e o Evento seja realizado, o Anfitrião e a PICOZ.CO poderão solicitar
                informações e documentos adicionais ao Organizador do Evento, conforme políticas e regras internas
                próprias do Anfitrião e da PICOZ.CO.
              </li>
            </ol>

            <li className='li-terms-and-conditions'>Preço, Pagamento e Cancelamento</li>

            <p>
              Após a confirmação da Reserva do Espaço, o Organizador do Evento estará sujeito às regras de pagamento e
              cancelamento determinadas pelo Anfitrião, conforme informado no anúncio do Espaço.{' '}
            </p>

            <p>
              O pagamento poderá ser realizado via boleto ou cartão de crédito, intermediado pela PAGAR.ME PAGAMENTOS
              S.A., plataforma de pagamentos eletrônicos segura e autorizada pelo Banco Central do Brasil. Através desse
              sistema, garantimos o pagamento ao Anfitrião, onde o valor da locação é cobrado integralmente do
              Organizador no ato da Reserva e liberado integralmente para o Anfitrião no dia do check-in do Organizador,
              caso não haja nenhuma desistência.{' '}
            </p>

            <p>
              A PICOZ.CO cobrará do Anfitrião taxa de sucesso de 10% (dez por cento) em sua locação por meio do site da
              PICOZ.CO, será cobrada uma remuneração do Espaço sobre o preço do aluguel do Espaço anunciado pelo
              Anfitrião por cada locação intermediada pelo site.
            </p>

            <p>
              A remuneração aqui prevista será devida à PICOZ.CO no momento da efetivação do aluguel do Espaço e será
              automaticamente debitada do valor pago pelo Organizador do Evento. Dessa forma, o Anfitrião receberá como
              pagamento o valor do aluguel do Espaço, descontada a remuneração da PICOZ.CO. O valor do aluguel poderá
              ser cobrado por hora ou por diária, definido de acordo com as referências de mercado e/ou sugerida sob
              consultoria de valorização pela PICOZ.CO para locação temporária.
            </p>

            <p>
              Sendo o Organizador do Evento uma pessoa jurídica e o Anfitrião pessoa física, ficará o Organizador do
              Evento responsável pela retenção e/ou pagamento de eventuais tributos aplicáveis.
            </p>

            <p>
              {' '}
              Organizador do evento e Anfitrião isentam a PICOZ.CO de qualquer responsabilidade decorrente da locação do
              Espaço, inclusive o não repasse do aluguel por falta de pagamento pelo Organizador do Evento ou sua
              insuficiência e/ou o não cumprimento de qualquer outra obrigação destes Termos e Condições de Uso .
            </p>

            <li className='li-terms-and-conditions'>Política de Cancelamento e Direito de Arrependimento</li>

            <p>
              A PICOZ.CO foi desenvolvida para ajudar os Anfitriões e os convidados a completar de forma eficiente e
              conveniente as reservas. Cada reserva é um compromisso do Anfitrião para disponibilizar um espaço para um
              Organizador. Quando os convidados cancelam as reservas, a capacidade do Anfitrião de reservar esse espaço
              durante esse período torna-se cada vez mais difícil porque outros convidados interessados já podem ter
              feito outros planos. Quando os Anfitriões cancelam as reservas, os eventos e planos dos Organizadores
              podem ser severamente afetados e podem exigir suporte logístico intensivo da PICOZ.CO. No entanto, os
              planos podem mudar, exigindo que os Organizadores ou Anfitriões cancelem as Reservas de vez em quando.
              Esta Política de Cancelamento de abrange todos os cancelamentos e reembolsos na Plataforma. Organizadores
              e Anfitriões estão sujeitos e devem cumprir esta Política de Cancelamento ao cancelar uma Reserva. Sem
              prejuízo e não obstante a política de cancelamento, o Anfitrião e Organizador tem o direito de cancelar
              uma Reserva efetuada dentro do prazo de 7 (sete) dias da data do recebimento da confirmação da Reserva
              Art. 49 do Código de Defesa do Consumidor - Lei 8078/90.{' '}
            </p>

            <p>
              Entendemos que cada espaço é diferente e que uma única política de cancelamento não se adequa a todas as
              circunstâncias da Reserva. É por isso que permitimos que os anfitriões escolham entre três políticas de
              cancelamento padronizadas (Flexível, Moderada e Rigorosa) que serão aplicadas para proteger tanto o
              Organizador quanto o anfitrião, conforme abaixo:
            </p>

            <ul>
              <li>
                Flexível: Organizadores podem cancelar sua reserva até 2 dias da data inicial do evento, podendo receber
                reembolso total (incluindo todas as taxas) do valor da reserva. Cancelamentos após esse período não são
                reembolsáveis.
                <br /> Organizadores podem cancelar a sua reserva entre de 2 dias antes e 24hrs da hora de início do
                evento e receber um reembolso de 50% (excluindo taxas) do valor da reserva.
                <br /> Cancelamentos após esse período não são reembolsáveis.
                <CancelationProgress className='desktop' cancelationType='flexivel'></CancelationProgress>
              </li>
              <li>
                Moderado (padrão): Organizadores podem cancelar sua reserva até 15 dias da data inicial do evento,
                podendo receber reembolso total (incluindo todas as taxas) do valor da reserva.
                <br />
                Organizadores podem cancelar a sua reserva entre 15 dias e 2 dias antes da hora de início do evento e
                receber um reembolso de 50% (excluindo taxas) do valor da reserva.
                <br />
                Cancelamentos após esse período não são reembolsáveis.
                <CancelationProgress className='desktop' cancelationType='moderado'></CancelationProgress>
              </li>
              <li>
                Rígido: Organizadores podem cancelar sua reserva até 30 dias da data inicial do evento, podendo receber
                reembolso total (incluindo todas as taxas) do valor da reserva. Cancelamentos após esse período não são
                reembolsáveis. Organizadores podem cancelar a sua reserva entre 30 dias e 15 dias antes da hora de
                início do evento e receber um reembolso de 50% (excluindo taxas) do valor da reserva. Cancelamentos após
                esse período não são reembolsáveis.
                <CancelationProgress className='desktop' cancelationType='rigido'></CancelationProgress>
              </li>
            </ul>

            <h4>Termos Adicionais</h4>
            <h4>Confirmação de cancelamento</h4>

            <p>
              Uma Resera é considerada oficialmente cancelada uma vez que o Organizador tenha recebido uma confirmação
              do cancelamento pela PICOZ.CO.
            </p>

            <h5>Pagamentos ao Anfitrião</h5>
            <p>
              O pagamento de taxas de cancelamento ao Anfitrião vai depender da política específca escolhida pelo
              Anfitrião e outras circunstancias. Se a PICOZ.CO, a seu exclusivo critério com base na política de
              cancelamento aplicável, determinar que um Organizador tem direito a um reembolso, o pagamento do Anfitrião
              será calculado deduzindo do valor total da Reserva (que inclui o Preço de Reserva e qualquer taxa
              adicionais do Anfitrião) ou quaisquer taxas de serviço da PICOZ.CO ou taxas de serviços de Concierge e, em
              seguida, reduzir esse valor pela porcentagem especificada na política de cancelamento aplicável.
              Geralmente, os pagamentos são feitos entre 3 a 7 dias após a confirmação do cancelamento.
            </p>
            <h5>Serviços oferecidos pelo Anfitrião</h5>
            <p>
              Serviços adicionais oferecidos pelo Anfitrião (excluing alimentação e bebidas) são sujeitos à mesma
              política do espaço. Cancelamentos de A e B são sujeitos à política de cancelamento optada pelo Anfitrião
              no hora da confirmação dos serviços adicionais.{' '}
            </p>
            <p>
              Para reservas de mais um dia, o prazo para o cancelamento estabelecido na política de cancelamento
              aplicável se aplica ao horário inicial do Evento para cada dia em que o Espaço for Reservado. Todo período
              de cancelamento é baseado no horário local do espaço.
            </p>

            <p>
              Reembolsos para Organizadores serão aplicados após o cancelamento ser finalizado. Os pagamentos aos
              Anfitriões serão feitos no prazo de 7 dias após a hora de início da reserva cancelada. Se um Anfitrião
              receber uma nova Reserva para o Espaço ao mesmo tempo que a Reserva cancelada, a PICOZ.CO poderá optar por
              devolver todo ou parte do pagamento que seria pago ao Anfitrião como parte do reembolso de cancelamento
              para o Organizador.
            </p>
            <p>
              Onde os Organizadors cancelarem uma parte de uma reserva de vários dias, as taxas e os reembolsos serão
              aplicados apenas às datas específicas canceladas. Os Organizadors podem atualizar o horário de reserva sem
              penalidade caso o Anfitrião concordar com o novo horário.
            </p>

            <h5>Cancelamentos feitos pelo fornecedor ou pelo Anfitrião</h5>

            <p>
              Quaisquer cancelamentos iniciados pelo Anfitrião ou Fornecedor serão totalmente reembolsados ao
              Organizador (incluindo quaisquer Taxas pagas). O Anfitrião ou o Fornecedor que cancelar a Reserva será
              responsável pelos custos incorridos pela PICOZ.CO ou pelo Organizador associado a qualquer cancelamento, e
              quaisquer pagamentos agendados para essa Reserva serão cancelados. Além disso, a PICOZ.CO poderá emitir
              Multas aos Anfitriões ou Fornecedores pelos cancelamentos e poderá (i) deduzir ou debitar quaisquer Multas
              do saldo a ser recebido pela reserva, ou (ii) enviar Multas como fatura a ser paga dentro de sete (7)
              dias.
            </p>
            <h5>Multas por Cancelamentos pelo Anfitrião</h5>
            <p>
              Reservas confirmadas que são canceladas no todo ou parcialmente por um Anfitrião estão sujeitas ao
              seguinte:
            </p>
            <ul>
              <li>
                Além de ser responsável pelos custos de deslocamento que a PICOZ.CO ou o Organizador podem incorrer como
                resultado de um Cancelamento iniciado pelo Anfitrião, os cancelamentos que não forem justificados
                resultarão em uma Multa de mais de RS $ 250 ou 15% do valor total da Reserva. Esta multa pode ser
                cobrada por um método de pagamento vinculado à sua conta ou deduzida ou debitada de qualquer quantia a
                ser recebida por uma future reserva.
              </li>
              <li>
                O cancelamento de várias reservas dentro de um período de 6 meses pode resultar na exclusão ou
                modificação da descrição do seu espaço para incluir informações disponíveis publicamente sobre
                cancelamentos anteriores ou suspensão ou encerramento de sua conta.
              </li>
            </ul>

            <p>
              A PICOZ.CO pode, mas não é obrigado a, renunciar a multas por cancelamentos iniciados pelo Anfitrião nas
              seguintes circunstâncias:
            </p>
            <p>
              {' '}
              Aceitação acidental de uma reserva cancelada dentro de 2 horas após a confirmação; Organizadors que
              forneçam informações falsas ou enganosas na solicitação de reserva; Organizadores sugerindo o uso de um
              Espaço de maneira inconsistente com a Descrição, o Contrato de Reserva ou outras limitações acordadas
              entre o Organizador e o Anfitrião; Atividade imprópria ou ilegal que ocorre durante uma reserva; Um
              Anfitrião e Organizador decidindo mutuamente no cancelamento da Reserva com um reembolso total do custo da
              Reserva para o Organizador; ou Qualquer cancelamento desculpado.
            </p>

            <h5>Cancelamentos Justificados</h5>

            <p>
              Em casos raros, pode ser necessário cancelar uma reserva confirmada devido a circunstâncias atenuantes sob
              aviso curto para uma das situações identificadas abaixo ("Cancelamento justificado"):
            </p>
            <ul>
              <li>
                Morte inesperada ou doença grave de um Anfitrião, Organizador ou membro imediato da família de um dos
                dois;
              </li>
              <li>
                Lesões graves que restringem diretamente a capacidade de um Organizador de viajar ou a capacidade de um
                anfitrião de fornecer o Espaço reservado;
              </li>
              <li>
                Desastres naturais ou incidentes de mal tempo que desencadeiam um estado de emergência que afetam
                diretamente o uso do Espaço ou a capacidade do Organizador de viajar para o Espaço;
              </li>
              <li>
                Restrições urgentes de viagem ou avisos de segurança severos emitidos após o momento da reserva, por um
                escritório ou agência governamental apropriada;
              </li>
              <li>
                Danos irrecuperáveis ao imóvel ou problemas de manutenção imprevistos que afetam diretamente o uso
                seguro do Espaço; ou
              </li>
              <li>Ordem jurídica, municipal ou liminar que restrinja diretamente o uso ou o acesso a um Espaço.</li>
            </ul>

            <p>
              A PICOZ.CO pode exigir que os Organizadores ou Anfitriãos forneçam evidências para suportar o cancelamento
              justificado. Quando aprovado, as Taxas de Reserva serão reembolsadas integralmente ao Organizador e nenhum
              pagamento será enviado ao Anfitrião sem Taxas de Cancelamento ou Multas.
            </p>

            <h5>Cancelamentos feitos pela PICOZ.CO</h5>

            <p>
              A PICOZ.CO pode decidir, a seu exclusivo critério, que é necessário ou desejável cancelar uma Reserva
              confirmada e emitir um reembolso total ou parcial a um Organizador por:
            </p>

            <ul>
              <li>Um cancelamento desculpado;</li>
              <li>A remoção de um Anfitrião ou espaço da plataforma antes do horário de início;</li>
              <li>Qualquer atividade potencialmente ilegal ou não autorizada;</li>
              <li>Risco de danos ou preocupações de segurança;</li>
              <li>
                Não fornecer comprovante de contrato de seguros, incluindo seguro de produção de eventos ou filmagem,
                quando solicitado pela PICOZ.CO;
              </li>
              <li>Quaisquer violações das Diretrizes da comunidade; ou</li>
              <li>Qualquer outro motivo.</li>
            </ul>

            <p>
              Quando a PICOZ.CO inicia um cancelamento, qualquer reembolso ou pagamento varia dependendo das
              circunstâncias que motivaram o cancelamento. Você compreende e concorda que nem a PICOZ.CO nem qualquer
              outra parte da reserva cancelada terá qualquer responsabilidade ou obrigação por tais cancelamentos
              iniciados pelo PICOZ.CO.
            </p>

            <h5>Cancelamento de serviços de fornecedores terceiros</h5>

            <p>
              As taxas para o cancelamento de Serviços de Fornecedores terceiros variam com base no Fornecedor e são
              fornecidas no momento em que a solicitação de Serviços do Fornecedor é Confirmada. Se uma política de
              cancelamento não for fornecida pelo Fornecedor, as Taxas de Cancelamento para Espaços listadas acima serão
              aplicadas, dependendo se o cancelamento for iniciado pelo Anfitrião, Organizador ou a PICOZ.CO. Embora os
              reembolsos de taxas de fornecedores sejam gerenciados por esta política de cancelamento, taxas de serviços
              de Concierge não são reembolsáveis para cancelamentos iniciados pelo Orgnizador ou Fornecedor.
            </p>

            <h5>Como cancelar uma reserva</h5>

            <p>
              Para cancelar uma reserva, você deve enviar uma solicitação de cancelamento usando a função de "Cancelar
              reserva" na plataforma (aplicativo ou site para dispositivos móveis). As solicitações de cancelamento não
              podem ser enviadas por meio de mensagens, telefonemas, bate-papos ou e-mails para a PICOZ.CO. Se você não
              conseguir localizar o botão de “Cancelar reserva”a na plataforma, entre em contato com nossa equipe de
              suporte via Chat ou e-mail.
            </p>

            <p>
              As solicitações de cancelamento enviadas podem ser postergadas em até 48 horas para permitir que qualquer
              uma das partes reconfirme, remarque ou finalize o cancelamento. Após esse período, o cancelamento será
              finalizado.
            </p>

            <h5>Reservas remarcadas</h5>

            <p>
              Quando aprovado pela PICOZ.CO, um Organizador ou Anfitrião poderá ter a oportunidade de remarcar a
              Reserva. Qualquer reserva remarcada deve ser:
            </p>

            <ul>
              <li>Pela mesma duração da Reserva original, e mediante aprovação do Anfitrião;</li>
              <li>Confirmado antes do horário de início original;</li>
              <li>Remarcado por um tempo dentro de 90 dias do horário de início original; e</li>
              <li>Remarcado apenas uma vez.</li>
            </ul>

            <p>
              Quaisquer reservas remarcadas não são reembolsáveis depois de confirmadas. Quaisquer tentativas sucessivas
              de remarcação ou falha ao remarcar uma Reserva com êxito nas condições acima resultarão no cancelamento da
              Reserva, sujeito às Taxas de Cancelamento ou Multas identificadas nesta Política, dependendo da parte que
              iniciou o cancelmento. Qualquer remarcação feita por Organizadores pode estar sujeita a uma Taxa de
              Processamento adicional pagável a PICOZ.CO.
            </p>

            <li className='li-terms-and-conditions'>Descadastramento no Site</li>

            <p>
              O Anfitrião poderá se descadastrar da PICOZ.CO, encerrando assim sua vinculação aos serviços prestados.
              Uma vez descadastrado, o Anfitrião poderá perder o acesso a algumas seções e funcionalidades do site.{' '}
            </p>

            <p>
              O Anfitrião que desejar realizar o seu descadastramento deverá enviar um e-mail para{' '}
              <b>
                <a href='mailto:contato@picoz.co'>contato@picoz.co</a>
              </b>
              , e aguardar até 48 horas para que seus Espaços deixem de ser divulgados como opções para realização de
              Eventos.
            </p>

            <p>
              Caso o Anfitrião tenha um Evento agendado para o seu Espaço, suas obrigações para com a PICOZ.CO e o
              Organizador do Evento perdurarão após o seu descadastramento.
            </p>

            <p>
              A PICOZ.CO não se responsabiliza por acordos de locação efetuados fora do site, e a desintermediação da
              PICOZ.CO de qualquer forma dos serviços que oferece e o direito do recebimento de pagamento resultará no
              descadastramento das partes envolvidas e uma multa do Anfitrião de 100% do valor de comissão da locação.
            </p>

            <p>
              Qualquer violação dos termos de uso por parte do Anfitrião ou Organizador do Evento ou parceiros resultará
              no descadastramento automático do infrator, mediante ações legais cabíveis.
            </p>

            <li className='li-terms-and-conditions'>Locação, Uso e Entrega do Espaço</li>

            <p>
              Após a realização da Reserva do Espaço por um Organizador do Evento com um Anfitrião, o Anfitrião se
              compromete a não realizar qualquer modificação no Espaço, salvo se essencial para o Evento, e sempre
              mediante notificação prévia e expressa ao Organizador do Evento e à PICOZ.CO.
            </p>

            <p>
              No momento do Evento, o Organizador do Evento se compromete a assegurar a conservação e integridade do
              Espaço, incluindo de sua mobília, decoração, obras de arte e demais objetos de valor, sendo responsável
              por indenizar o Anfitrião no valor do prejuízo sofrido em caso de qualquer dano causado por si ou por seus
              convidados em decorrência do Evento ou sua preparação.
            </p>

            <p>
              O Espaço será devolvido pelo Organizador do Evento ao Anfitrião nas exatas condições que o recebeu,
              devidamente higienizado e organizado. O Organizador do Evento não está autorizado a realizar obras ou
              benfeitorias no Espaço.
            </p>

            <p>
              O Organizador do Evento e o Anfitrião reconhecem que, uma vez confirmada uma Reserva, e assinado
              eletronicamente o Contrato de Locação Temporária do Espaço, estará configurada a relação de Locador e
              Locatário entre Anfitrião e o Organizador do Evento e respectivamente. A relação de locação entre o
              Organizador de Evento e o Anfitrião vigorará a partir da data estipulada de Reserva do Espaço pela
              plataforma da PICOZ.CO e Contrato de Locação Temporária do Espaço, e será regida pelas disposições
              aplicáveis nestes Termos e Condições de Uso, no Contrato de Locação Temporária do Espaço e pela legislação
              aplicável.
            </p>

            <p>
              O Anfitrião se compromete a disponibilizar o Espaço limpo e atendendo à todas as especificações indicadas
              no formulário de cadastro e expressas no site da PICOZ.CO.
            </p>

            <p>
              O Anfitrião se compromete a informar a PICOZ.CO em caso de qualquer modificação realizada no Espaço após a
              curadoria, e desde já concorda que alterações consideradas pela PICOZ.CO como impedimentos temporários ou
              permanentes para a locação do Espaço levarão à sua remoção, temporária ou permanente, do site e
              indenização quanto aos prejuízos sofridos pela PICOZ.CO e pelo Organizador de Eventos.
            </p>

            <li className='li-terms-and-conditions'>Overbooking</li>

            <p>
              Na hipótese de o Anfitrião se equivocar em relação à agenda interna de seu Espaço e possuir Eventos
              conflitantes ocorrendo no mesmo horário de Reserva feita pelo Organizador do Evento no Espaço, o Anfitrião
              deverá notificar a PICOZ.CO e o Organizador do Evento imediatamente dentro de um prazo de até 7 (sete)
              dias. Não sendo possível alcançar um acordo quanto à utilização do Espaço ou na hipótese de o overbooking
              ser informado num prazo inferior a 7 dias da data do Evento, todo o valor pago deverá ser ressarcido pelo
              Anfitrião ao Organizador do Evento, devendo ainda o Anfitrião pagar a comissão à PICOZ.CO mesmo não
              ocorrendo o Evento, além de ressarcir qualquer prejuízo causado à PICOZ.CO ou ao Organizador do Evento
              dentro de 48H da comprovação do prejuízo causado.
            </p>

            <p>
              Havendo overbooking, o Anfitrião poderá ser suspenso temporária ou permanentemente da PICOZ.CO, a depender
              do caso.
            </p>

            <p>
              A PICOZ.CO fará o possível para garantir a disponibilidade do Espaço nas datas solicitadas pelo
              Organizador do Evento e evitar conflitos de Reservas de Eventos no mesmo Espaço.
            </p>

            <p>
              Na eventualidade de overbooking entre dois Eventos intermediados pela PICOZ.CO, terá prioridade aquele que
              realizou a Reserva anteriormente. O Organizador do Evento que fez a segunda Reserva terá seu dinheiro
              devolvido, sem taxas, e o direito de escolher outro Espaço, nas mesmas condições, desde que este esteja
              disponível na data desejada.
            </p>

            <li className='li-terms-and-conditions'>Declarações da PICOZ.CO</li>

            <p>Nestes Termos e Condições de Uso, a PICOZ.CO declara e você reconhece e concorda que:</p>

            <ol type='a'>
              <li>
                A PICOZ.CO atua como intermediadora de serviços de locação entre o Anfitrião e Organizador do Evento. O
                Organizador do Evento deve agir com responsabilidade na realização dos negócios decorrentes de qualquer
                anúncio disponibilizado no site. Não há responsabilidade da PICOZ.CO em indenizar, restituir ressarcir
                qualquer dano, lesão ou prejuízo sofrido pelo Anfitrião ou Organizador do Evento decorrente do acordo
                realizado entre os mesmos, ou seja, qualquer questão envolvendo qualidade, segurança, ocupação ou
                legalidade do Evento, são de responsabilidade exclusiva do Anfitrião ou do Organizador do Evento.
              </li>

              <li>
                A PICOZ.CO pode, a qualquer momento, retirar de seu site qualquer serviço, no todo ou em parte, por
                qualquer motivo, sem que isto gere qualquer direito ao Organizador do Evento ou Anfitrião, ou ônus à
                PICOZ.CO.
              </li>

              <li>
                A PICOZ.CO não assume qualquer responsabilidade quanto ao cumprimento de quaisquer leis e regulamentos
                que possam ser aplicados a qualquer Espaços listados no site por um Anfitrião.
              </li>
            </ol>

            <li className='li-terms-and-conditions'>Propriedade Intelectual e Conteúdo</li>

            <p>
              Os usuários do site concedem à PICOZ.CO uma licença perpétua, mundial, irrevogável e irrestrita, não
              exclusiva, livre de royalties, sem qualquer remuneração, para, de forma integral ou parcial, usar, copiar,
              licenciar, sublicenciar (em vários níveis), adaptar, distribuir, publicar, exibir, executar publicamente,
              reproduzir, transmitir, modificar, editar, e de qualquer outra forma explorar qualquer conteúdo fornecido
              ou publicado no site, como comentários, avaliações e fotos de Eventos realizados, por exemplo, além do
              conteúdo que tenha sido enviado para a PICOZ.CO e editado pela equipe PICOZ.CO antes da publicação.
            </p>

            <p>
              O site e todo o conteúdo da PICOZ.CO são protegidos por direitos autorais como um trabalho coletivo e/ou
              compilado, de acordo com as leis nacionais e internacionais de direitos autorais, assim como por
              convenções internacionais das quais o Brasil seja signatário.
            </p>

            <p>
              Você concorda em cumprir todos e quaisquer avisos de direitos autorais, informações ou restrições contidas
              ou relativas a qualquer conteúdo, conforme disposto nestes termos.
            </p>

            <p>
              O Anfitrião autoriza a PICOZ.CO a utilizar o conteúdo publicado no site a respeito do Espaço em todos os
              meios de comunicação, por meio de qualquer tecnologia e em quaisquer meios de comunicação (incluindo, mas
              não se limitando a e-mail, e-mail marketing, blogs, vídeos, banners, revistas, jornais, mídia eletrônica e
              Eventos), para uso e proveito pela PICOZ.CO de tal conteúdo, seja total ou parcialmente, em qualquer
              modalidade, inclusive para fins comerciais (releases, ações de marketing, mídias sociais, dentre outros).
            </p>

            <p>
              O Anfitrião concorda em cumprir todos e quaisquer avisos de direitos autorais, informações ou restrições
              contidas ou relativas a qualquer conteúdo, conforme disposto nestes Termos e Condições de Uso. O Anfitrião
              declara e reconhece que as fotografias tiradas pela PICOZ.CO no Espaço são e permanecerão propriedade da
              PICOZ.CO.
            </p>

            <p>
              As marcas, logotipos, marcas de serviço e nomes comerciais que aparecem no site da PICOZ.CO são de
              exclusividade da PICOZ.CO. O Anfitrião obedecerá a todos os avisos, informações e restrições sobre
              direitos autorais incluídos em qualquer conteúdo acessado por intermédio dos nossos serviços. O site da
              PICOZ.CO é protegido por direitos autorais segundo leis e convenções internacionais sobre direitos
              autorais.
            </p>

            <p>
              O Anfitrião é exclusivamente responsável por todo conteúdo de Anfitrião que disponibilizar no, ou por meio
              do, site da PICOZ.CO. Assim, o Anfitrião declara e garante que:
            </p>
            <ol type='i'>
              <li>
                É o proprietário único e exclusivo de todo o conteúdo que disponibilizar para a PICOZ.CO ou no site, ou
                tem todos os direitos, licenças, autorizações e liberações necessárias para conceder à PICOZ.CO os
                direitos sobre o referido conteúdo, conforme aqui contemplado, e{' '}
              </li>

              <li>
                Seu conteúdo, publicação, upload, apresentação ou transmissão ou uso de conteúdo, na plataforma PICOZ.CO
                não infringirá, desapropriará ou violará uma patente de terceiro, direitos autorais, marca registrada,
                segredo comercial, direitos morais ou outros direitos proprietários ou de propriedade intelectual, ou
                direitos de publicidade ou privacidade, ou resultará na violação de qualquer lei ou regulamento
                aplicável.
              </li>
            </ol>

            <li className='li-terms-and-conditions'>Obrigações do Anfitrião e do Organizador de Eventos</li>

            <p>Obrigações do Anfitrião:</p>

            <ol type='i'>
              <li>
                Entregar o Espaço em condições satisfatórias de higiene e organização para o Organizador do Evento;
              </li>

              <li>
                Garantir que não exista Reserva de Eventos que coincidam na data da Reserva, assegurando que a
                realização de um Evento não inviabilize ou prejudique a de outro, sob pena de multa e indenização,
                conforme previsto nesses Termos e Condições de Uso (“Overbooking”);
              </li>

              <li>
                Realizar a entrega do Espaço em consonância com as especificações estabelecidas no formulário de
                cadastramento e de acordo com o verificado durante a curadoria;
              </li>

              <li>
                Não realizar quaisquer modificações no Espaço sem avisar prévia e expressamente à PICOZ.CO e ao
                Organizador do Evento, conforme detalhado nestes Termos e Codições de Uso;
              </li>

              <li>
                Assegurar que o Espaço possui todas as licenças e autorizações necessárias para a realização do Evento
                reservado; e
              </li>

              <li>
                Não realizar qualquer tipo de discriminação em virtude de raça, sexo, cor, origem, condição social ou
                orientação sexual;
              </li>
            </ol>

            <p>Obrigações do Organizador do Evento:</p>

            <ol type='i'>
              <li>
                Zelar pelo Espaço do Evento, assegurando a sua conservação física e a integridade de toda a mobília,
                decoração, obras de arte, e demais elementos que o componham;
              </li>

              <li>
                Retornar o Espaço ao Anfitrião nas mesmas condições de conservação, organização e higiene nas quais o
                Espaço foi recebido antes do Evento;
              </li>

              <li>Respeitar a legislação municipal, estadual e federal;</li>

              <li>
                Controlar os níveis de ruído, especialmente em Eventos noturnos, em atendimento aos artigos 146, 147 e
                148 da lei municipal nº 16.402/2016;
              </li>

              <li>
                Possuir todas as licenças necessárias para realizar o Evento, estando de acordo com as obrigações legais
                decorrentes do exercício de sua atividade;
              </li>

              <li>
                Tomar as devidas providências para assegurar que não haverá consumo de substâncias ilícitas no Evento,
                nem o consumo de bebidas alcoólicas por menores de idade;
              </li>

              <li>
                Não realizar qualquer tipo de discriminação em virtude de raça, sexo, cor, origem, condição social ou
                orientação sexual;
              </li>

              <li>Não realizar qualquer atividade ilícita no Espaço;</li>

              <li>
                Responsabilizar-se pelos atos de seus convidados, devendo indenizar à PICOZ.CO e/ou ao Anfitrião por
                quaisquer perdas e danos causados em razão da realização do Evento;
              </li>

              <li>
                Não realizar a exploração comercial para benefício próprio do site ou dos serviços, a qualquer título e
                a qualquer tempo, sob pena de multa e indenização por perdas e danos à PICOZ.CO e ao Anfitrião. Não é
                permitida, por exemplo, a sublocação do Espaço para um terceiro.
              </li>
            </ol>

            <li className='li-terms-and-conditions'>Parceiros</li>

            <p>
              O Organizador de Eventos poderá a sua discrição contratar Serviços de Terceiros por meio da plataforma da
              PICOZ.CO como parte de sua Reserva. Os prestadores dos Serviços de Terceiros são terceirizados,
              independentes, e não são afiliados ou estão sob o controle da PICOZ.CO. Os Serviços de Terceiros estarão
              disponíveis para Reserva por meio da plataforma ou podemos ajudá-lo por meio dos nossos serviços de
              concierge.A PICOZ.CO não se responsabiliza por quaisquer promessas ou falha de promessa de entrega de
              Serviços de Terceiros ou pelo Anfitrião, pela natureza ou qualidade de quaisquer serviços não prestado
              diretamente pela PICOZ.CO, e por qualquer perda ou dano possam sofrer decorrentes ou relacionados aos
              Serviços de Terceiros ou do Anfitrião. As Partes reconhecem que a PICOZ.CO pode receber comissão de um ou
              mais fornecedores em conexão com uma Reserva de Serviços de Terceiros.
            </p>

            <li className='li-terms-and-conditions'>Serviços de Concierge</li>

            <p>
              Nós fornecemos serviços de concierge para ajudar o Organizador do Evento a contratar serviços adicionais
              para seu Evento (“Serviço de Concierge”). Os Serviços de Concierge pode não estar disponível para Espaços
              específicos ou para atender as necessidades específicas. Quando cabível, trabalharemos com o Organizador
              do Evento para estabelecer um orçamento e outros requisitos para a sua Reserva, e Sendo possível atender a
              solicitação do Organizador do Evento por meio do Serviço de Concierge enviaremos propostas para
              consideração e aceitação. Caso aceito, coordenaremos qualquer comunicação e pagamento entre o Organizador
              do Evento e Serviço de Terceiros. O Organizador do Evento ficará sujeito ao pagamento à PICOZ.CO pelas
              taxas dos Serviços de Concierge, mesmo que os Serviços de Terceiros sejam confirmados ou pagos fora da
              plataforma.
            </p>

            <p>
              Como em todas as Reservas, a PICOZ.CO não é responsável e expressamente se isenta de qualquer
              responsabilidade por ações ou omissões de anfitriões ou parceiros, e por qualquer perda ou dano sofridos
              decorrentes ou relacionados aos Serviços de Terceiros.
            </p>

            <li className='li-terms-and-conditions'>Utilização do Site</li>
            <ol>
              <li className='sub-li'>Direitos da PICOZ.CO. </li>
              <p>Independente da seção navegada pelas Partes a PICOZ.CO se Reserva o direito de:</p>
              <ol type='i'>
                <li>
                  Adicionar cookies ao seu domínio com a finalidade de rastrear os visitantes de seu site, assim como
                  aqueles que tenham concluído com sucesso um teste de CAPTCHA;
                </li>

                <li>
                  Adicionar códigos de rastreamento e/ou afiliados aos links que não tenham códigos de rastreamento e/ou
                  semelhantes;
                </li>

                <li>
                  Adicionar scripts às suas páginas para, por exemplo, adicionar serviços ou executar rastreamento de
                  performances adicionais;
                </li>

                <li>Interceptar solicitações que possam ser ameaças e exibi-las com uma página de bloqueio;</li>

                <li>
                  Implementar quaisquer outras mudanças para elevar o nível de desempenho ou segurança de seu site.
                </li>
              </ol>

              <li className='sub-li'>Restrições de Uso. O Organizador do Evento e o Anfitrião não poderão:</li>
              <ol type='i'>
                <li>
                  Modificar, traduzir ou de outro modo criar trabalhos derivados de qualquer parte do site ou serviços;
                </li>

                <li>
                  Decifrar, descompilar, desmontar, fazer engenharia reversa ou de outro modo tentar derivar qualquer
                  código-fonte ou ideias subjacentes ou algoritmos de qualquer parte de quaisquer serviços, a não ser na
                  medida das leis aplicáveis que especificamente proíbam essa restrição;
                </li>

                <li>
                  Copiar, alugar, arrendar, distribuir ou de outro modo transferir qualquer dos direitos que você receba
                  nos termos do presente;
                </li>

                <li>
                  Modificar, publicar, transmitir, participar da transferência ou venda, reproduzir (a não ser conforme
                  estipulado nestes termos de uso), criar obras derivadas com base em, distribuir, realizar, mostrar ou
                  de qualquer forma explorar qualquer conteúdo, software, materiais ou serviços no todo ou em parte;
                </li>

                <li>
                  Interferir ou tentar interferir de qualquer maneira no trabalho apropriado do site e serviços, acesso
                  a outros clientes ou uso do site ou serviços;
                </li>

                <li>De outro modo tomar qualquer medida em violação das diretrizes e políticas da PICOZ.CO.</li>

                <li>
                  Reproduzir qualquer elemento do site, inclusive o ambiente de moldura ou borda do site, ou outra
                  técnica de construção para incluir parte do site em site terceiro;
                </li>

                <li>Utilizar-se do site para publicar ou transmitir informações falsas, fraudulentas, enganosas;</li>
              </ol>

              <li className='sub-li'>Licença de Uso</li>

              <p>
                Você possui uma licença limitada, revogável e não-exclusiva para acessar o site e os serviços, sempre em
                conformidade com o disposto nestes Termos e Condições de Uso. Qualquer utilização do site que não esteja
                expressamente permitida, deve ser entendida como proibida.
              </p>

              <p>
                Você concorda que a licença concedida não inclui o direito de agregação, captura (scraper), coleta,
                exibição de uso de derivados no site, duplicação, nem qualquer uso de seleção dos dados (data mining),
                robôs, spiders, ou ferramenta similar para coleta e utilização de dados, sem a autorização prévia e
                expressa da PICOZ.CO.
              </p>

              <p>
                Você não poderá modificar, publicar, transmitir, transferir, vender, reproduzir, criar trabalhos
                derivados baseados, distribuir, realizar engenharia reversa, desenvolver ou de qualquer forma explorar o
                conteúdo, software, materiais ou serviços no todo ou em parte.
              </p>

              <p>
                O Organizador do Evento poderá acessar ou utilizar o conteúdo da PICOZ.CO e do Anfitrião somente para
                uso dos serviços selecionados, desde que observe e respeite todos os avisos de direitos de propriedade
                intelectual da PICOZ.CO e do Anfitrião. O acesso, utilização, cópia ou armazenagem de qualquer conteúdo
                para qualquer uso que não dos serviços selecionados para fins exclusivos de utilização pessoal são
                expressamente proibidos. O Organizador do Evento não poderá, por exemplo, utilizar as imagens ou
                descrições dos sspaços como se eles fossem de sua propriedade ou autoria, tampouco reproduzi-las.
              </p>

              <li className='sub-li'>Não-Interferência.</li>

              <ol type='i'>
                <li>
                  O Anfitrião e Organizador do Evento declaram, garantem e concordam que não interferirão de qualquer
                  maneira nos Serviços, de forma a:
                </li>

                <li>
                  Infringir, violar ou de outro modo interferir em qualquer direito autoral, marca ou qualquer direito
                  de propriedade intelectual de outros, ou a privacidade ou direitos de publicidade de terceiros;
                </li>

                <li>
                  Revelar qualquer segredo comercial ou informação confidencial, a menos que possua esse segredo
                  comercial ou tenha permissão do proprietário de postá-lo ou compartilhá-lo;
                </li>

                <li>
                  Disseminar qualquer conteúdo que seja considerado difamatório, obsceno, pornográfico, abusivo,
                  indecente, ameaçador, assediador, incitador de ódio, ofensivo ou de outro modo viole qualquer lei ou
                  direito de qualquer terceiro;
                </li>

                <li>
                  Copiar, reproduzir, fazer upload, exibir, publicar, distribuir ou transmitir o conteúdo de qualquer
                  forma;
                </li>

                <li>Praticar, de qualquer maneira, phising;</li>

                <li>
                  Disseminar vírus, arquivos maliciosos, cavalos de tróia, worms, bombas relógio ou outras rotinas de
                  programação de computador que tenham o objetivo de danificar, interferir de forma prejudicial,
                  interceptar ou desapropriar quaisquer sistemas, dados ou informações, ou;
                </li>

                <li>
                  Não cessar a violação, após ter sido notificado de que está violando qualquer dos itens (i) a (iv)
                  acima.
                </li>
              </ol>
            </ol>
            <br />
            <p>
              A PICOZ.CO Reserva-se o direito de cancelar os serviços prestados ao Organizador do Evento e/ou remover de
              seu site os comentários do Organizador do Evento, a qualquer momento, por qualquer motivo, inclusive
              mediante recebimento de demandas ou alegações de terceiros ou autoridades em relação a esse conteúdo ou
              caso a PICOZ.CO tenha preocupações sobre a violação destes termos de uso.
            </p>

            <li className='li-terms-and-conditions'>Desempenho e Segurança</li>

            <p>
              A PICOZ.CO envidará esforços razoáveis para garantir que o site da PICOZ.CO e os Serviços estejam
              disponíveis e seguros 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana. Contudo, haverá
              ocasiões em que o site da PICOZ.CO e/ou os Serviços poderão ser interrompidos por diversos motivos,
              incluindo, mas sem se limitar, para manutenção, upgrades e reparos emergenciais ou devido a falhas de
              telecomunicações e equipamentos, ataques, propagação de vírus em massa, novas vulnerabilidades, entre
              outros.
            </p>

            <p>
              As Partes mencionadas nesses Termos e Condições de Uso concordam que a PICOZ.CO não será responsável, em
              nenhum caso, perante o Anfitrião, Organizador do Evento, ou qualquer outra parte, por qualquer suspensão,
              modificação, interrupção ou falta de disponibilidade do site, dos serviços, seu conteúdo ou outro
              conteúdo.
            </p>

            <li className='li-terms-and-conditions'>Renúncia à Garantia</li>

            <p>
              O Organizador do Evento e o Anfitrião liberam a PICOZ.CO de toda responsabilidade por ter ou não locado um
              Espaço por intermédio da PICOZ.CO. A PICOZ.CO não presta quaisquer garantias ou declarações em relação a
              qualquer conteúdo contido ou acessado por intermédio do site.
            </p>

            <p>
              Na medida mais ampla permitida por lei, os serviços, conteúdo e site são fornecidos “no estado em que se
              encontram”, sem garantias de qualquer tipo, sejam expressas ou implícitas, inclusive, sem limitação,
              garantias de comerciabilidade, adequação para uma finalidade específica ou não infração.
            </p>

            <p>
              Na medida mais ampla permitida por lei, a PICOZ.CO renuncia a qualquer responsabilidade pela exatidão,
              confiabilidade, disponibilidade, integralidade, legalidade ou operabilidade do conteúdo, serviços ou site.
              Ao utilizar este site ou os serviços, o Organizador do Evento reconhece que a PICOZ.CO não é responsável
              por nenhum dano resultante da:
            </p>

            <ol typy='i'>
              <li>Locação do Espaço;</li>

              <li>
                Impossibilidade temporária ou permanente de acessar ou receber qualquer conteúdo do site, inclusive, sem
                limitação, em decorrência de danos causados por vírus, worms, cavalos de Tróia ou qualquer contaminação
                similar ou programa destrutivo;
              </li>

              <li>Acesso ou uso de informações contidas no site ou nos serviços; e</li>

              <li>Conteúdo disponibilizado no site conforme informações prestadas pelo Anfitrião.</li>
            </ol>
            <p>
              Nos casos em que existe divergência em contrato celebrado entre o Anfitrião e o Organizador do Evento, os
              termos do contrato prevalecem, em relação a estes, sobre esses Termos e Condições de Uso, sendo a PICOZ.CO
              isenta de quaisquer disputas ou controvérsias que derivem da relação direta entre Anfitrião e o
              Organizador do Evento, atuando como mera intermediária.
            </p>

            <li className='li-terms-and-conditions'>Limitação de Responsabilidade</li>

            <p>
              Em nenhum caso a PICOZ.CO, seus conselheiros, administradores, sócios, ou funcionários serão responsáveis
              em relação ao site ou Serviços por:
            </p>

            <ol type='i'>
              <li>
                Garantir o cumprimento de todas as leis, regras e regulamentos que possam ser aplicados a quaisquer
                Espaços listados no site.
              </li>

              <li>Quaisquer danos diretos, indiretos ou imprevistos de qualquer tipo;</li>

              <li>Danos por perda de uso, lucros, dados, imagens, conteúdo, ou outros bens intangíveis;</li>

              <li>Danos por uso não autorizado, por impossibilidade de acesso ao site, erros ou omissões; ou</li>

              <li>
                Controvérsias judiciais ou extrajudiciais decorrentes de desentendimentos entre o Anfitrião e o
                Organizador do Evento ocorridas antes, durante ou após a celebração do Evento no Espaço.
              </li>
            </ol>

            <li className='li-terms-and-conditions'>Indenização</li>

            <p>
              O Organizador do Evento e o Anfitrião indenizará e isentará a PICOZ.CO, e suas afiliadas, fornecedores,
              licenciadores, conselheiros, diretores e seus respectivos funcionários e agentes, inclusive custos e
              honorários de advogados, de quaisquer demandas oriundas do acesso ao site, utilização dos serviços,
              violação destes Termos e Condições de Uso, infração de qualquer direito de propriedade intelectual ou
              outros direitos de qualquer outra pessoa física ou jurídica.
            </p>

            <p>
              O Organizador do Evento concorda em indenizar e manter a PICOZ.CO isenta de todo e qualquer ônus, dever ou
              responsabilidade no caso de o Organizador do Evento usar os Serviços ou o Espaço de forma a violar
              qualquer lei local aplicável.
            </p>

            <p>
              Em caso de dano à propriedade móvel ou imóvel do Anfitrião, incluindo o Espaço em si, a mobília,
              decoração, eventuais obras de arte e demais elementos presentes no Espaço durante o Evento, por parte do
              Organizador do Evento, qualquer convidado, prestador de serviços, ou terceiro não relacionado ao
              Anfitrião, o Organizador do Evento deverá tomar as medidas necessárias para reparar o dano, as suas
              custas. Não sendo possível providenciar a reparação do dano causado, o Organizador do Evento indenizará o
              Anfitrião pelos prejuízos sofridos.
            </p>

            <p>
              A PICOZ.CO não se responsabilizará por qualquer dano causado ao Espaço, mobília, decoração, eventuais
              obras de arte e demais elementos presentes no Espaço por ação ou omissão do Organizador do Evento ou de
              seus convidados.
            </p>

            <li className='li-terms-and-conditions'>Modificações</li>

            <p>
              A PICOZ.CO Reserva-se o direito, a seu critério, de modificar estes termos de uso ou o conteúdo do site, a
              qualquer momento, postando um aviso no site, ou enviando-lhe uma notificação via e-mail ou por meio dos
              serviços prestados. Você será responsável por analisar e aceitar tais modificações. Para continuar
              utilizando os serviços, será necessário manifestar o consentimento em relação às alterações. Caso o
              Anfitrião não concorde com qualquer parte dos Termos e Condições de Uso então em vigor, seu uso dos
              Serviços terminará imediatamente, e deverão acessar imediatamente a utilização dos Serviços da PICOZ.CO.
            </p>

            <li className='li-terms-and-conditions'>Geral</li>

            <p>
              Se qualquer disposição desses Termos e Condições de Uso for considerada inexequível ou inválida, essa
              disposição será limitada, ou eliminada, para o mínimo necessário, de modo que esses termos de uso de outro
              modo permaneçam em pleno vigor e efeito e sejam exequíveis.
            </p>

            <p>Estes termos de uso serão regidos e interpretados de acordo com as leis do Brasil.</p>

            <p>
              Esses Termos e Condições de Uso (inclusive a política de privacidade e quaisquer outras políticas,
              diretrizes, FAQs publicados no site ou em relação aos Serviços) são a declaração completa e exclusiva de
              entendimento mútuo da PICOZ.CO com o Anfitrião e o Organizador do Evento e substitui e cancela todos os
              acordos verbais escritos e prévios, comunicações e outros entendimentos relativos aos Serviços.
            </p>

            <p>
              Qualquer notificação ao site que seja exigida será por escrito e considerada vigente mediante recebimento
              quando enviada por e-mail com aviso de recebimento a contato@PICOZ.CO.
            </p>

            <li className='li-terms-and-conditions'>Feedback</li>

            <p>
              É importante que o Anfitrião nos reporte quaisquer bugs no site, conteúdos ou serviços por meio do e-mail
              contato@PICOZ.CO
            </p>

            <p>
              Quaisquer comentários, sugestões ou feedback em relação ao site, conteúdos ou serviços (em conjunto,
              “Feedback”) enviados à PICOZ.CO se tornarão propriedade da PICOZ.CO.
            </p>

            <li className='li-terms-and-conditions'>Foro</li>

            <p>
              A PICOZ.CO deseja ter a melhor relação jurídica possível com o Anfitrião e Organizador de Eventos e sempre
              tentará resolver os conflitos de maneira amigável. Se isso não for possível, fica eleito o Foro da Comarca
              de São Paulo, Estado de São Paulo, com expressa renúncia de qualquer outro, por mais privilegiado que
              seja.
            </p>
          </ol>
        </div>
      </Modal.Body>
    </Modal>
  )
}
