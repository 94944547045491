import * as firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/firestore'

const config = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  appId: process.env.REACT_APP_PROD_APP_ID,
}
// const config = {
//   apiKey: process.env.REACT_APP_DEV_API_KEY,
//   authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
//   projectId: process.env.REACT_APP_DEV_PROJECT_ID,
//   messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_DEV_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
// }
// const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig
// const config = prodConfig
// const config = devConfig

class Firebase {
  constructor() {
    firebase.initializeApp(config)
    firebase.analytics()

    this.auth = firebase.auth()
    this.db = firebase.firestore()
  }

  signUpWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password)

  signInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password)

  passwordReset = email => this.auth.sendPasswordResetEmail(email)

  passwordUpdate = password => this.auth.currentUser.updatePassword(password)
}
export default Firebase
