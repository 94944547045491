import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Check from '@material-ui/icons/Check'
import CheckIcon from '@material-ui/icons/Check'
import ViewAgendaIcon from '@material-ui/icons/CalendarToday'
import StepConnector from '@material-ui/core/StepConnector'
import './cancelation.scss'
import { cancelation } from '../../constants'
const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
})

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg,rgb(15,59,125) 0%,rgb(240,48,141) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg,rgb(15,59,125) 0%,rgb(240,48,141) 100%)',
    },
  },
  line: {
    height: 14,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: 'rgb(240,48,141)',

    // backgroundImage: 'linear-gradient( 95deg,rgb(15,59,125) 0%,rgb(15,59,125) 50%,rgb(240,48,141) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    // backgroundColor: 'rgb(15,59,125)',
    backgroundImage: 'linear-gradient( 95deg,rgb(15,59,125) 0%,rgb(15,59,125) 50%,rgb(240,48,141) 100%)',

    // backgroundImage: 'linear-gradient( 95deg,rgb(15,59,125) 0%,rgb(240,48,141) 50%,rgb(240,48,141) 100%)',
  },
})

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <CheckIcon />,
    4: <ViewAgendaIcon />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 48,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    border: '1px solid',
    padding: 12,
    backgroundColor: 'white',
    width: 180,
  },
}))

export default function CancelationProgress({ cancelationType = 'flexivel' }) {
  const classes = useStyles()
  const refOne = createRef()
  const refTwo = createRef()
  const refThree = createRef()

  const messages = cancelation[cancelationType]

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={3} connector={<ColorlibConnector />}>
        <Step key={0}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>Reserva Confirmada</StepLabel>
        </Step>
        <Step key={1} ref={refOne}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>{messages.first}</StepLabel>
        </Step>
        <Step key={2} ref={refTwo}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>{messages.second}</StepLabel>
        </Step>
        <Step key={3} ref={refThree}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>Dia do evento</StepLabel>
        </Step>
      </Stepper>
    </div>
  )
}
