import React from 'react'
import styled from 'styled-components'
import colors from '../../constants/colors'

const ErrorMessage = styled.div`
  color: ${colors.redError};
  font-size: 13px;
  font-weight: 300;
  position: relative;
  top: -7px;
  left: 3px;
`

export default function InputError({ children, ...props }) {
  return <ErrorMessage {...props}>{children}</ErrorMessage>
}
