import React from 'react'
import styled from 'styled-components'
import { colors } from '../../constants'

const InternalButton = styled.button`
  padding: 0 7px;
  margin: 0 5px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
  color: ${colors.gray};
  background-color: transparent;
  border-color: transparent;
  font-family: 'Metropolis';

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: underline;
    color: ${colors.grayDark};
  }

  &:active {
    text-decoration: underline;
    color: ${colors.grayDark};
  }
`

export default function TextButton({ children, ...props }) {
  return <InternalButton {...props}>{children}</InternalButton>
}
