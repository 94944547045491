import React from 'react'
import styled from 'styled-components'
import InputError from './InputError'

const InternalInput = styled.input`
  display: block;
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  margin: 10px 1px;
  font-size: 18px;
  line-height: 1.428571429;
  color: #303133;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-size: 15px;

  &:focus {
    outline: none;
  }
`

export default function InputForm({ errorMsg, ...props }) {
  return (
    <>
      <InternalInput {...props} />
      {errorMsg && <InputError>{errorMsg}</InputError>}
    </>
  )
}
