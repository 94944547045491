import React, { forwardRef, useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import user_placeholder from '../../../assets/images/user_placeholder.png'
import { Dropdown } from 'react-bootstrap'
import { FirebaseContext } from '../../Firebase'

const AvatarWrapper = styled.div`
  margin: 0px 5px;
  height: 50px;
  width: 50px;
  background: no-repeat center center;
  background-size: cover;
  background-image: url(${props => props.imageUrl});
  border-radius: 25px;
  cursor: pointer;
`

export default function Avatar({ user, area, setArea }) {
  const firebase = useContext(FirebaseContext)
  const history = useHistory()
  const image = user && user.avatar_url ? user.avatar_url : user_placeholder

  // eslint-disable-next-line react/display-name
  const AvatarButton = forwardRef(({ children, onClick }, ref) => {
    return (
      <AvatarWrapper
        ref={ref}
        imageUrl={image}
        onClick={e => {
          e.preventDefault()
          onClick(e)
        }}
      >
        {children}
      </AvatarWrapper>
    )
  })

  const signOut = () => {
    firebase.auth
      .signOut()
      .then(() => {
        return history.push('/')
      })
      .catch(() => {
        // console.log(error)
      })
  }

  const OrganizerMenu = () => (
    <>
      <Dropdown.Item onClick={() => history.push('/profile')}>Meu Perfil</Dropdown.Item>
      <Dropdown.Item onClick={() => history.push('/organizador/reservas')}>Reservas</Dropdown.Item>

      <Dropdown.Item onClick={() => history.push('/organizador/pagamentos')}>Pagamentos</Dropdown.Item>

      {user.is_host && (
        <Dropdown.Item
          onClick={() => {
            setArea('host')
            history.push('/anfitriao')
          }}
        >
          Área do Anfitrião
        </Dropdown.Item>
      )}
    </>
  )

  const HostMenu = () => (
    <>
      <Dropdown.Item onClick={() => history.push('/profile')}>Meu Perfil</Dropdown.Item>
      <Dropdown.Item onClick={() => history.push('/anfitriao')}>Dashboard</Dropdown.Item>

      <Dropdown.Item
        onClick={() => {
          setArea('organizer')
          history.push('/espacos')
        }}
      >
        Área do organizador
      </Dropdown.Item>
    </>
  )

  return (
    <Dropdown>
      <Dropdown.Toggle id='dropdown-custom-components' as={AvatarButton} />
      <Dropdown.Menu>
        {area === 'organizer' && <OrganizerMenu />}
        {area === 'host' && <HostMenu />}
        <Dropdown.Divider />
        <Dropdown.Item onClick={signOut}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
