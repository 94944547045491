import React, { useContext } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'

import { useApi } from '../../../hooks'
import { FirebaseContext } from '../../Firebase'
import { UserContext } from '../../../context'
import firebase from 'firebase'

const GoogleButton = styled.button`
  color: white;
  margin: 20px 0;
  background-color: #db4437;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }
`

export default function GoogleLogin({ callback, setIsLoading }) {
  const _firebase = useContext(FirebaseContext)
  const { setUser } = useContext(UserContext)
  const api = useApi()

  const handleLogin = async () => {
    setIsLoading(true)
    const provider = new firebase.auth.GoogleAuthProvider()

    try {
      const result = await _firebase.auth.signInWithPopup(provider)
      if (!result.additionalUserInfo.isNewUser) {
        setIsLoading(false)
        return callback()
      }

      const payload = {
        token: result.user.xa,
        first_name: result.additionalUserInfo.profile.given_name,
        last_name: result.additionalUserInfo.profile.family_name,
        profile_picture: result.additionalUserInfo.profile.picture,
      }

      try {
        const { data } = await api.post('/user', payload)
        setIsLoading(false)
        setUser(data.user)
        window.localStorage.setItem('user', JSON.stringify(data.user))
        window.localStorage.setItem('token', JSON.stringify(result.user.xa))
        return callback()
      } catch (error) {
        // if user can not be create, use must be delete from firebase
        await _firebase.auth.currentUser.delete()
        setIsLoading(false)
        return callback(true, 'Houve um problema, tente novamente')
      }
    } catch (error) {
      setIsLoading(false)
      // Handle Errors here.
      // console.log('Error:', error)
      // The email of the user's account used.
      // The firebase.auth.AuthCredential type that was used.

      return callback(true, 'Houve um problema, tente novamente')
    }
  }

  return (
    <GoogleButton onClick={handleLogin}>
      <FontAwesomeIcon icon={faGoogle} />
      &nbsp; Entrar com Google
    </GoogleButton>
  )
}
