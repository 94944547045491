import { useState, useEffect, useContext } from 'react'
import { FirebaseContext } from '../components/Firebase'
import { useApi } from '../hooks'

export default function useAuth() {
  const api = useApi()

  const setSavedUser = () => {
    const savedUser = localStorage.getItem('user')
    if (savedUser) {
      return JSON.parse(savedUser)
    }
  }

  const firebase = useContext(FirebaseContext)
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(setSavedUser)

  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(currentUser => {
      if (currentUser && currentUser.emailVerified) {
        api.defaults.headers.authorization = currentUser.xa

        api
          .get('/user')
          .then(response => {
            setUser(response.data.user)
            window.localStorage.setItem('user', JSON.stringify(response.data.user))
            window.localStorage.setItem('token', JSON.stringify(currentUser.xa))
            return setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
      } else {
        setUser(null)
        setLoading(false)
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('token')
      }
    })

    return unsubscribe
  }, [])

  return [user, setUser, loading]
}
