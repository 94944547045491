import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { UserContext } from '../../context'
import * as firebase from 'firebase/app'

export default function ProtectedRoute({ path, component }) {
  const { user } = useContext(UserContext)

  if (user) {
    return <Route path={path} component={component} />
  } else {
    return <Redirect to='/' />
  }
}
