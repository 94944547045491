import React, { useState } from 'react'
import styled from 'styled-components'
import { TextButton } from '../../../components'
import TermAndConditionsModal from '../../../components/TermsAndConditionsModal'
import { colors } from '../../../constants'

const CancellationPolicyWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
  border: 1px solid ${colors.grayLight};
  border-radius: 4px;
  margin-bottom: 35px;
  padding: 10px;
`

export default function HostCancellationPolicy() {
  const [showModal, setShowModal] = useState(false)

  return (
    <CancellationPolicyWrapper>
      <h4 style={{ fontWeight: 'bold' }}>Informação Importante</h4>
      <h5>Políticas de Cancelamento para Anfitriões</h5>
      <p>
        Cancelamento de um evento já confirmado pode resultar em uma custo de deslocamento e uma penalidade de 5% do
        valor total do custo do evento
      </p>
      <TextButton onClick={() => setShowModal(true)}>Veja os termos e condições</TextButton>
      <TermAndConditionsModal show={showModal} setShow={setShowModal} />
    </CancellationPolicyWrapper>
  )
}
