import React, { useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { slide as MobileMenu } from 'react-burger-menu'

import { useNavbar } from './hooks'
import { NavbarWrapper, Menu, NavbarFiller, MobileMenuItem, AvatarWrapper, DrawerDivider } from './styled'
import Logo from '../Logo'
import { TextButton, BlueButton } from '../Buttons'
import LoginModal from './components/LoginModal'
import Avatar from './components/Avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import user_placeholder from '../../assets/images/user_placeholder.png'
import { FirebaseContext } from '../Firebase'

import Notifications from './components/Notifications'

export default function Navbar() {
  const firebase = useContext(FirebaseContext)
  const history = useHistory()

  const [showModal, setShowModal, action, handleActionClick, user, setAction, area, setArea] = useNavbar()
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const NewUserMenu = () => {
    return (
      <>
        <Link to='/saiba-mais'>
          <BlueButton>Seja Anfitrião</BlueButton>
        </Link>
        <TextButton
          onClick={() => {
            handleActionClick('sign_in')
          }}
        >
          Entrar
        </TextButton>
      </>
    )
  }

  const NewUserMenuMobile = () => {
    return (
      <>
        <Link to='/saiba-mais'>
          <BlueButton>Seja Anfitrião</BlueButton>
        </Link>
        <TextButton
          style={{ padding: 0, textAlign: 'right' }}
          onClick={() => {
            handleActionClick('sign_in')
          }}
        >
          Entrar
        </TextButton>
      </>
    )
  }

  const signOut = () => {
    setShowMobileMenu(false)

    firebase.auth
      .signOut()
      .then(() => {
        return history.push('/')
      })
      .catch(() => {})
  }

  const OrganizerUserMenu = () => {
    return (
      <>
        {!user.is_host && (
          <Link to='/anunciar-espaco'>
            <BlueButton>Seja Anfitrião</BlueButton>
          </Link>
        )}

        <Link to='/organizador/reservas'>
          <TextButton>Reservas</TextButton>
        </Link>

        <Link to='/espacos'>
          <TextButton>Espaços </TextButton>
        </Link>

        <Link to='/organizador/favoritos'>
          <TextButton>Favoritos </TextButton>
        </Link>

        <Notifications />

        <Avatar user={user} area={area} setArea={setArea}></Avatar>
      </>
    )
  }

  const HostUserMenu = () => {
    return (
      <>
        <Link to='/anunciar-espaco'>
          <BlueButton>Anunciar Espaço</BlueButton>
        </Link>

        <Notifications />

        <Avatar user={user} area={area} setArea={setArea}></Avatar>
      </>
    )
  }

  const Drawer = () => {
    return (
      <MobileMenu
        width={'70%'}
        slide
        isOpen={showMobileMenu}
        onStateChange={state => setShowMobileMenu(state.isOpen)}
        right
        customBurgerIcon={false}
        disableAutoFocus
      >
        <MobileMenuUser />
      </MobileMenu>
    )
  }

  const handleMobileMenuItemClick = path => {
    setShowMobileMenu(false)
    history.push(path)
  }

  const MobileMenuUser = () => {
    return (
      <>
        <AvatarWrapper src={user.avatar_url || user_placeholder} />
        <p>Bem-vindo {user.first_name}</p>
        <DrawerDivider />
        <MobileMenuItem
          onClick={() => {
            handleMobileMenuItemClick('/profile')
          }}
        >
          Meu Perfil
        </MobileMenuItem>

        <MobileMenuItem
          onClick={() => {
            handleMobileMenuItemClick('/espacos')
          }}
        >
          Espaços
        </MobileMenuItem>

        <MobileMenuItem
          onClick={() => {
            handleMobileMenuItemClick('/organizador/favoritos')
          }}
        >
          Favoritos
        </MobileMenuItem>

        {/* <MobileMenuItem
          onClick={() => {
            handleMobileMenuItemClick('/organizador/notificacoes')
          }}
        >
          Notificações
        </MobileMenuItem>

        <MobileMenuItem
          onClick={() => {
            handleMobileMenuItemClick('/organizador/mensagens')
          }}
        >
          Messagens
        </MobileMenuItem> */}
        <DrawerDivider />

        <MobileMenuItem
          onClick={() => {
            handleMobileMenuItemClick('/organizador/pagamentos')
          }}
        >
          Pagamentos
        </MobileMenuItem>

        <MobileMenuItem
          onClick={() => {
            handleMobileMenuItemClick('/organizador/reservas')
          }}
        >
          Reservas
        </MobileMenuItem>
        <MobileMenuItem
          onClick={() => {
            handleMobileMenuItemClick('/anunciar-espaco')
          }}
        >
          {user.is_host ? 'Anunciar Espaço' : ' Seja Anfitrião'}
        </MobileMenuItem>
        <DrawerDivider />
        {/* {user.is_host && (
          <MobileMenuItem
            onClick={() => {
              handleMobileMenuItemClick('/anfitriao')
            }}
          >
            Area do Anfitrião
          </MobileMenuItem>
        )} */}

        {user.is_host && (
          <MobileMenuItem
            onClick={() => {
              handleMobileMenuItemClick('/anfitriao/espacos')
            }}
          >
            Meus Espaços
          </MobileMenuItem>
        )}

        {user.is_host && (
          <MobileMenuItem
            onClick={() => {
              handleMobileMenuItemClick('/anfitriao/reservas')
            }}
          >
            Reservas em meus Espaços
          </MobileMenuItem>
        )}

        {/* {user.is_host && (
          <MobileMenuItem
            onClick={() => {
              handleMobileMenuItemClick('/anfitriao/agenda')
            }}
          >
            Minha Agenda
          </MobileMenuItem>
        )} */}

        {user.is_host && (
          <MobileMenuItem
            onClick={() => {
              handleMobileMenuItemClick('/anfitriao/pagamentos')
            }}
          >
            Meus Pagamentos
          </MobileMenuItem>
        )}

        <MobileMenuItem
          onClick={() => {
            signOut()
          }}
        >
          Logout
        </MobileMenuItem>
      </>
    )
  }

  const displayMenu = () => {
    if (user) {
      if (area === 'organizer') {
        return <OrganizerUserMenu />
      }

      if (area === 'host') {
        return <HostUserMenu />
      }
    } else {
      return <NewUserMenu />
    }
  }

  return (
    <>
      {user && <Drawer />}
      <NavbarFiller />
      <NavbarWrapper className='navbar fixed-top navbar-light navbar-index'>
        <Link to='/' className='navbar-brand'>
          <Logo />
        </Link>

        <div className='d-none d-lg-block'>
          <Menu>{displayMenu()}</Menu>
        </div>

        {user ? (
          <FontAwesomeIcon
            className='d-block d-lg-none'
            icon={faBars}
            style={{ fontSize: '30px', color: '#f0308d', marginRight: '15px' }}
            onClick={() => setShowMobileMenu(true)}
          />
        ) : (
          <div className='d-block d-lg-none' style={{ maxWidth: '210px' }}>
            <div style={{ display: 'flex' }}>
              <NewUserMenuMobile />
            </div>
          </div>
        )}
      </NavbarWrapper>
      <LoginModal show={showModal} setShow={setShowModal} action={action} setAction={setAction} />
    </>
  )
}
