import React from 'react'
import ReactDOM from 'react-dom'

import './assets/fonts/AvenirLTStd-Book.otf'
import './assets/fonts/AvenirLTStd-Medium.otf'
import './assets/fonts/AvenirLTStd-Roman.otf'
import './assets/fonts/Metropolis-Black.otf'
import './assets/fonts/Metropolis-BlackItalic.otf'
import './assets/fonts/Metropolis-BoldItalic.otf'
import './assets/fonts/Metropolis-ExtraBold.otf'
import './assets/fonts/Metropolis-ExtraBoldItalic.otf'
import './assets/fonts/Metropolis-ExtraLight.otf'
import './assets/fonts/Metropolis-ExtraLightItalic.otf'
import './assets/fonts/Metropolis-Light.otf'
import './assets/fonts/Metropolis-LightItalic.otf'
import './assets/fonts/Metropolis-Medium.otf'
import './assets/fonts/Metropolis-MediumItalic.otf'
import './assets/fonts/Metropolis-Regular.otf'
import './assets/fonts/Metropolis-RegularItalic.otf'
import './assets/fonts/Metropolis-SemiBold.otf'
import './assets/fonts/Metropolis-SemiBoldItalic.otf'
import './assets/fonts/Metropolis-SemiBoldItalic.otf'
import './assets/fonts/Metropolis-Thin.otf'
import './assets/fonts/Metropolis-ThinItalic.otf'

import App from './App'
import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.min.css'
import Firebase, { FirebaseContext } from './components/Firebase'
import './assets/styles/index.scss'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { ToastProvider } from 'react-toast-notifications'

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Metropolis'].join(','),
  },
})

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <App />
      </ToastProvider>
    </ThemeProvider>
  </FirebaseContext.Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
// serviceWorker.register()
