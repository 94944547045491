import React from 'react'
import styled from 'styled-components'
import colors from '../../constants/colors'
import picozWhiteLogo from '../../assets/images/white_logo.svg'

import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedinIn, faMediumM } from '@fortawesome/free-brands-svg-icons'
import { mediaQueries } from '../../constants/mediaQueries'

const FooterWrapper = styled.footer`
  background-color: ${colors.blue};
  color: ${colors.white};
  text-decoration: none;
  box-shadow: 0px 0px 10px 1px ${colors.grayLight};

  #footer-top {
    padding: 2.5em 4em;
    display: flex;

    h5 {
      color: ${colors.white};
      font-weight: 700;
      margin-bottom: 15px;
    }

    li {
      font-size: 14px;
      text-align: center;
      color: ${colors.white};
      margin: 3px 0;
    }

    @media screen and (max-width: ${mediaQueries.sm}) {
      flex-direction: column-reverse;
      padding: 0.3em !important;
    }

    #footer-left {
      flex: 7;
      display: flex;
      @media screen and (max-width: ${mediaQueries.sm}) {
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 65px;
      }

      .list-style {
        margin: 20px 25px;
        min-width: 100px;
        h5 {
          text-align: left;
          @media screen and (max-width: ${mediaQueries.sm}) {
            text-align: left;
          }
        }

        @media screen and (max-width: ${mediaQueries.sm}) {
          margin: 20px 30px;
          align-items: flex-start;
          text-align: left;
        }

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          li {
            text-align: left;
          }
          li a {
            color: ${colors.white} !important; /* blue colors for links too */
            text-decoration: inherit;
          }
        }
      }
    }

    #footer-right {
      flex: 3;
      align-content: flex-end;
      float: right;
      margin-right: 20px;
      @media screen and (max-width: ${mediaQueries.sm}) {
        margin: 30px;
        float: left;
      }
      .social {
        text-align: right;
        h5 {
          margin: 10px 0;
        }
        @media screen and (max-width: ${mediaQueries.sm}) {
          text-align: left;
          padding-top: 20px;
        }
      }

      .logo {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        @media screen and (max-width: ${mediaQueries.sm}) {
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      .questions {
        text-align: right;

        h5 {
          margin: 10px 0;
        }
        p {
          font-size: 15px;
        }
      }

      .social-icons {
        font-size: 48px;
        padding: 0px 8px;
        color: ${colors.white};
      }
      @media screen and (max-width: ${mediaQueries.sm}l) {
        margin-right: 30px;
        justify-content: flex-start;
      }
    }
  }
`

export default function Footer() {
  return (
    <FooterWrapper>
      <div id='footer-top'>
        <div id='footer-left'>
          <div className='list-style'>
            <h5>Ocasiões</h5>
            <ul>
              <li>
                <Link to='/espacos?occasions=5'>Reunião</Link>
              </li>
              <li>
                <Link to='/espacos?occasions=4'>Produção</Link>
              </li>
              <li>
                <Link to='/espacos?occasions=1'>Festa</Link>
              </li>
              <li>
                <Link to='/espacos?occasions=1'>Casamento</Link>
              </li>
              <li>
                <Link to='/espacos?occasions=20'>Palestra</Link>
              </li>
            </ul>
          </div>
          <div className='list-style'>
            <h5>Bairros</h5>
            <ul>
              <li>
                <Link to={'/espacos?neighborhoods=Cidade%20Jardim'}>Cidade Jardim</Link>
              </li>
              <li>
                <Link to={'/espacos?neighborhoods=Pinheiros'}>Pinheiros</Link>
              </li>
              <li>
                <Link to={'/espacos?neighborhoods=Jardins'}>Jardins</Link>
              </li>
              <li>
                <Link to={'/espacos?neighborhoods=Centro'}>Centro</Link>
              </li>
            </ul>
          </div>
          <div className='list-style'>
            <h5>Empresa</h5>
            <ul>
              <li>
                <a href='https://medium.com/@picozco'>Blog</a>
              </li>
              <li>
                <a href='mailto:contato@picoz.com'>Fale conosco</a>
              </li>
              <li>
                <Link to='/politicas-de-privacidade'>Política de privacidade</Link>
              </li>
              <li>
                <Link to='/termos-e-condicoes'>Termos e condições</Link>
              </li>
            </ul>
          </div>
        </div>
        <div id='footer-right'>
          <div className='logo'>
            <img src={picozWhiteLogo} />
          </div>
          <div className='social'>
            <a href='https://www.facebook.com/picoz.co/'>
              <FontAwesomeIcon icon={faFacebook} className='social-icons' />
            </a>
            <a href='https://www.instagram.com/picoz.co'>
              <FontAwesomeIcon icon={faInstagram} className='social-icons' />
            </a>
            <a href='https://medium.com/@picozco'>
              <FontAwesomeIcon icon={faMediumM} className='social-icons' />
            </a>
            <a href='https://www.linkedin.com/company/picoz-co'>
              <FontAwesomeIcon icon={faLinkedinIn} className='social-icons' />
            </a>
          </div>
        </div>
      </div>
    </FooterWrapper>
  )
}
