import React, { useContext, useState } from 'react'
import { PinkButton, TextButton } from '../../Buttons'
import { InputForm } from '../../FormComponents'
import * as Yup from 'yup'
import Alert from '@material-ui/lab/Alert'
import { FirebaseContext } from '../../Firebase'
import { useFormik } from 'formik'
import toastr from 'toastr'

export default function SignInForm({ callback, setAction, setIsLoading }) {
  const firebase = useContext(FirebaseContext)
  const [errorMessage, setErrorMessage] = useState('')

  const Schema = Yup.object().shape({
    email: Yup.string().required('Email Obrigatório'),
    confirm_new_password: Yup.string().when('new_password', {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('new_password')], 'As duas senhas devem ser iguais'),
    }),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Schema,
    validateOnMount: true,
  })

  function makeLogin() {
    firebase
      .signInWithEmailAndPassword(formik.values.email, formik.values.password)
      .then(async response => {
        setIsLoading(false)

        if (!response.user.emailVerified) {
          setErrorMessage('Email não verficado, te enviamos um email para verificar.')
          await response.user.sendEmailVerification()
          await firebase.auth.signOut()
        } else {
          // validate errors
          return callback()
        }
      })
      .catch(error => {
        setIsLoading(false)

        if (error.code.includes('user-not-found')) {
          setErrorMessage('Usuário não encontrado')
        }
        if (error.code.includes('wrong-password')) {
          setErrorMessage('Senha incorreta')
        }
        if (error.code.includes('invalid-email')) {
          setErrorMessage('Insira um email válido')
        }
        if (error.code.includes('too-many-request')) {
          setErrorMessage('Tente Novamente mais tarde')
        }
      })
  }

  return (
    <div>
      {errorMessage.length > 0 && <Alert severity='error'>{errorMessage}</Alert>}

      <form onSubmit={formik.handleSubmit}>
        <InputForm
          type='email'
          name='email'
          placeholder='E-mail'
          autoComplete='username'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        <InputForm
          type='password'
          name='password'
          placeholder='Senha'
          autoComplete='password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
        />

        <PinkButton
          type='button'
          onClick={() => makeLogin()}
          style={{ width: '100%', margin: '10px 0' }}
          disabled={formik.isSubmitting}
        >
          Entrar
        </PinkButton>
        <div style={{ textAlign: 'center' }}>
          <TextButton onClick={() => setAction('sign_up')}>Não tem conta, cadastre-se</TextButton>
        </div>
      </form>
    </div>
  )
}
