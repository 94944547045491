import React from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'
import styled from 'styled-components'

import organizerPlaceholder from '../../assets/images/user_placeholder.png'
import { formatMoney } from '../../utils'
import { BaseCard, TitleLeft, SubTitleLeft, SubTitleCenter } from '../globalComponents'

const ReservationWrapper = styled(BaseCard)`
  padding: 10px;
`

const ReservationAvatar = styled.img`
  height: 100px;
  border-radius: 50%;
`

export default function HostReservationCard({ reservation }) {
  const { organizer } = reservation

  const renderContactDetails = () => {
    if (reservation.status === 'approved') {
      return (
        <div className='contact-details'>
          <SubTitleCenter style={{ fontWeight: 'bold' }}>Informação de Contato</SubTitleCenter>
          <p>{organizer.email}</p>
          <p>{organizer.phone}</p>
        </div>
      )
    } else {
      return null
    }
  }

  const renderDateTime = () => {
    const startDate = moment(reservation.start_date).utc().format('DD/MM/YYYY')
    const endDate = moment(reservation.end_date).utc().format('DD/MM/YYYY')

    if (startDate !== endDate) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '8px',
          }}
        >
          <div>
            <b>{startDate}</b>
          </div>
          <div> - </div>
          <div>
            <b>{endDate}</b>
          </div>
        </div>
      )
    } else {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '8px',
          }}
        >
          <div>
            <b>{startDate}</b>
          </div>
        </div>
      )
    }
  }

  return (
    <ReservationWrapper>
      <div>
        <ReservationAvatar src={organizer.avatar_url || organizerPlaceholder} />
        <h5>
          {organizer.first_name} {organizer.last_name}
        </h5>
      </div>

      {renderContactDetails()}
      <hr></hr>
      <div className='reservation-details'>
        <TitleLeft>Detalhes da Reserva</TitleLeft>
        <div>
          <SubTitleLeft>Descrição do Evento</SubTitleLeft>
          {reservation.occasion} <br />
          {reservation.quantity} Pessoas <br />
          {reservation.description}
        </div>
        <div className='date-and-time'>
          <SubTitleLeft>Data & Horário</SubTitleLeft>
          {renderDateTime()}
        </div>

        <div style={{ width: '100%' }}>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>
                  <SubTitleLeft>Preço</SubTitleLeft>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ paddingBottom: '8px', textAlign: 'left' }}>
                  {formatMoney(reservation.day_price)} x {reservation.total_days} dia
                </td>
                <td style={{ paddingBottom: '8px', textAlign: 'right' }}>{formatMoney(reservation.total)}</td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '8px', textAlign: 'left' }}>Taxa de serviço (%{reservation.picoz_fee})</td>
                <td style={{ paddingBottom: '8px', textAlign: 'right' }}>({formatMoney(reservation.total_fee)})</td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '8px', textAlign: 'left' }}>Taxa de transação</td>
                <td style={{ paddingBottom: '8px', textAlign: 'right' }}>
                  ({formatMoney(reservation.transaction_fee)})
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '8px', textAlign: 'left' }}>Total a receber</td>
                <td style={{ textAlign: 'right' }}>{formatMoney(reservation.total_without_fee)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ReservationWrapper>
  )
}
