import React, { useState, Suspense } from 'react'
import { hot } from 'react-hot-loader/root'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { LoadScript } from '@react-google-maps/api'
import logo from './assets/images/logo_picoz.png'

import { UserContext } from './context'
import { useAuth } from './hooks'
import { ProtectedRoute } from './components'
import { routes } from './constants/routes'

const Navbar = React.lazy(() => import('./components/Navbar/index'))

// const DriftChat = React.lazy(() => import('./components/DriftChat'))

function App() {
  const [showModal, setShowModal] = useState(false)
  const [user, setUser] = useAuth()
  const [area, setArea] = useState('organizer')

  return (
    <UserContext.Provider value={{ user, setUser, showModal, setShowModal, area, setArea }}>
      <LoadScript id='script-loader' googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
        <Router>
          <Suspense
            fallback={
              <div className='full-page-loader'>
                <img width='200' src={logo} alt='PicozLogo' />
              </div>
            }
          >
            <Navbar></Navbar>
            <Switch>
              {routes.map(item =>
                item.private ? (
                  <ProtectedRoute key={item.path} path={item.path} component={item.component}></ProtectedRoute>
                ) : (
                  <Route key={item.path} path={item.path} exact component={item.component} />
                )
              )}
            </Switch>
            {/* <DriftChat /> */}
          </Suspense>
        </Router>
      </LoadScript>
    </UserContext.Provider>
  )
}

export default hot(App)
