import { useContext } from 'react'
import axios from 'axios'
import { FirebaseContext } from '../components/Firebase'

export default function useApi() {
  const firebase = useContext(FirebaseContext)
  let headers = { 'Access-Control-Allow-Origin': '*' }

  let token = localStorage.getItem('token')

  if (token) {
    token = token.replace(/"/g, '')
    headers = { ...headers, authorization: token }
  }

  if (firebase.auth.currentUser) {
    headers = { ...headers, authorization: firebase.auth.currentUser.xa }
  }

  let baseURL = () => {
    if (process.env.NODE_ENV === 'production') {
      return process.env.REACT_APP_PROD_API_ENDPOINT
    } else {
      return process.env.REACT_APP_DEV_API_ENDPOINT
    }
  }

  const instance = axios.create({
    baseURL: baseURL(),
    // timeout: 5000,
    headers,
  })
  return instance
}
