export const messages = {
  error: {
    standard_msg: 'Houve um problema, tente mais tarde',
  },
  spaceStatus: {
    incompleted: {
      text: 'Incompleto',
      color: '#d50000',
    },
    awating_approval: {
      text: 'Aguardando aprovação',
      color: '#ff6d00',
    },
    approved: {
      text: 'Aprovado',
      color: '#00c853',
    },
  },
  boletoStatus: {
    created: 'Aguardando Geração',
    waiting_payment: 'Aguardando Pagamento',
    cancel: 'Cancelado',
    processing: 'Processando',
    paid: 'Pago',
  },
}
