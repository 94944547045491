import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import GoogleLogin from './GoogleLogin'
import Spinner from '../../Spinner'

import { SignUpForm, SignInForm } from '../../Forms'

import { TitleCenter, TextCenter } from '../../globalComponents'

export default function LoginModal({ show, setShow, action, setAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [showActions, setShowActions] = useState(true)
  const [error, setError] = useState()

  const formCallback = (error, msg) => {
    if (error) {
      setError(msg)
    } else {
      setError(null)
      setShow(false)
    }
  }

  useEffect(() => {
    setShowActions(true)
  }, [show])

  const signUpCallback = () => {
    setShowActions(false)
  }

  const actions = {
    sign_up: <SignUpForm callback={signUpCallback} setAction={setAction} setIsLoading={setIsLoading} />,
    sign_in: <SignInForm callback={formCallback} setAction={setAction} setIsLoading={setIsLoading} />,
  }

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <TitleCenter>Bem-vindo à Picoz!</TitleCenter>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {/* <FacebookLogin callback={formCallback} /> */}
            {showActions && (
              <>
                <GoogleLogin callback={formCallback} setIsLoading={setIsLoading} />
                <TextCenter>Ou use seu email...</TextCenter>
              </>
            )}

            {actions[action]}
          </>
        )}
        {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
      </Modal.Body>
    </Modal>
  )
}
