import styled from 'styled-components'
import colors from '../../constants/colors'

const NAVBAR_HEIGHT = '80px'

export const NavbarWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  height: ${NAVBAR_HEIGHT};
  background-color: ${colors.white};
  z-index: 999 !important;

  @media screen and (max-width: 600px) {
    padding: 0 5px !important;
  }
`
export const NavbarFiller = styled.div`
  height: ${NAVBAR_HEIGHT};
`

export const DrawerDivider = styled.hr`
  width: 100%;
  border-top: 1px solid ${colors.grayLight};
  margin: 0 0 0.7rem 0;
  max-width: 200px;
`

export const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MobileMenuItem = styled.p`
  font-size: 22px;
  color: white;
  margin-bottom: 6px;

  &:active {
    color: ${colors.pinkActive};
  }
`
export const AvatarWrapper = styled.div`
  margin: 0 0 1.5rem 0;
  height: 60px;
  width: 60px;
  background: no-repeat center center;
  background-size: cover;
  background-image: url(${props => props.src});
  border-radius: 50px;
`
