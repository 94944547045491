import React, { useEffect, useContext, useState } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import { UserContext } from '../../../context'
import { TitleLeft } from '../../../components/globalComponents'
import { useApi } from '../../../hooks'
import ReservationCard from '../../../components/HostReservationCard'
import HostCancellationPolicy from './HostCancellationPolicy'
import ReservationStatus from './ReservationStatus'
import MessagesBoard from '../../../components/MessageBoard'
import Spinner from '../../../components/Spinner'
import { messages } from '../../../constants/messages'

export default function Reservation() {
  const { addToast } = useToasts()

  const { user } = useContext(UserContext)
  const [reservation, setReservation] = useState(null)
  const [status, setStatus] = useState(null)

  const api = useApi()
  const { id } = useParams()

  useEffect(() => {
    const fetch = async () => {
      const reservationResponse = await api.get(`/host/reservations/${id}/manege_reservation`)
      setReservation(reservationResponse.data.reservation)
    }
    fetch()
  }, [status])

  const handleStatusChange = async status => {
    try {
      await api.patch(`/host/reservations/${id}/update_status`, {
        status: status,
      })
      setStatus(status)
    } catch (error) {
      addToast(messages.error.standard_msg, {
        appearance: 'error',
        autoDismiss: true,
      })
      console.log('')
    }
  }

  return reservation ? (
    <Container fluid>
      <TitleLeft>Reserva em {reservation.space.name}</TitleLeft>
      <Row>
        <Col md={3}>
          <ReservationCard reservation={reservation} />
        </Col>
        <Col md={6}>
          <MessagesBoard
            reservationId={reservation.id}
            isHost={true}
            avatarUrl={user.avatar_url}
            otherAvatarUrl={reservation.organizer_avatar}
          />
        </Col>
        <Col md={3}>
          <ReservationStatus reservation={reservation} handleStatusChange={handleStatusChange} />
          <HostCancellationPolicy />
        </Col>
      </Row>
    </Container>
  ) : (
    <Spinner />
  )
}
