import React, { useContext, useState, useEffect, useRef, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { TextArea } from '../FormComponents'
import { PinkButton } from '../Buttons'
import { FirebaseContext } from '../Firebase'
import Message from './Message'
import { TitleLeft } from '../globalComponents'

const MessageBoardWrapper = styled.div``

const MessageList = styled.div`
  margin-top: 10px;
  max-height: 60vh;
  overflow-y: scroll;

  padding-right: 10px;
`
const MessageForm = styled.div`
  margin-top: 10px;
  align-items: center;
  display: flex;
`

const MessageListWrapper = styled.div``

export default function MessagesBoard({ reservationId, isHost, avatarUrl, otherAvatarUrl }) {
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const messageListRef = useRef(null)

  useLayoutEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight
    }
  }, [messages])

  const firebase = useContext(FirebaseContext)
  const reservationRef = firebase.db.collection('reservations').doc(reservationId.toString()).collection('messages')

  useEffect(() => {
    const unsubcribe = reservationRef.orderBy('createdAt', 'desc').onSnapshot(function (querySnapshot) {
      let _messages = []

      querySnapshot.forEach(doc => {
        _messages.push(doc.data())
      })

      setMessages(_messages.reverse())
    })

    return unsubcribe
  }, [])

  const submitMessage = () => {
    if (message && message.length) {
      reservationRef.add({
        message: message,
        organizer: !isHost,
        host: isHost,
        createdAt: new Date().getTime(),
      })

      setMessage('')
    }
  }

  return (
    <MessageBoardWrapper>
      <TitleLeft>Mensagens</TitleLeft>
      <MessageListWrapper>
        <MessageList ref={messageListRef}>
          {messages.map((message, index) => {
            return (
              <Message
                key={index}
                avatarUrl={isHost === message.host ? avatarUrl : otherAvatarUrl}
                owner={isHost === message.host}
                message={message.message}
                createdAt={message.createdAt}
              />
            )
          })}
        </MessageList>
      </MessageListWrapper>
      <MessageForm>
        <TextArea value={message} onChange={e => setMessage(e.target.value)} />
        <PinkButton onClick={submitMessage} style={{ maxHeight: 20 }}>
          Enviar
        </PinkButton>
      </MessageForm>
    </MessageBoardWrapper>
  )
}
