import React from 'react'
import logo from '../assets/images/logo_picoz.png'
import styled from 'styled-components'

const Image = styled.img`
  height: 40px;
  margin: 10px;

  @media screen and (max-width: 600px) {
    height: 30px;
    margin: 5px;
  }
`

export default function LogoPicoz() {
  return <Image src={logo} />
}
